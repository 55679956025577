import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'ods-ozg-logo-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ozg-logo-icon.component.html',
})
export class OzgLogoIconComponent {}
