/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiError, TypedActionCreatorWithProps } from '@alfa-client/tech-shared';
import { UserProfileResource } from '@alfa-client/user-profile-shared';
import { createAction, props } from '@ngrx/store';
import { UserSettings, UserSettingsResource } from '../user-settings.model';

export interface UserProfileAction {
  currentUser: UserProfileResource;
}

export interface LoadedUserSettingsAction {
  userSettings: UserSettingsResource;
}

export interface SetUserSettingsAction {
  userSettings: UserSettings;
}

export interface ApiErrorAction {
  apiError: ApiError;
}

export const loadUserSettings: TypedActionCreatorWithProps<UserProfileAction> = createAction(
  '[UserSettings] Load UserSettings',
  props<{ currentUser: UserProfileResource }>(),
);

export const loadUserSettingsSuccess: TypedActionCreatorWithProps<LoadedUserSettingsAction> =
  createAction(
    '[UserSettings/API] Load UserSettings Success',
    props<{ userSettings: UserSettingsResource }>(),
  );

export const loadUserSettingsFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[UserSettings/API] Load UserSettings Failure',
  props<ApiErrorAction>(),
);

export const setUserSettings: TypedActionCreatorWithProps<SetUserSettingsAction> = createAction(
  '[UserSettings] Set UserSettings',
  props<{ userSettings: UserSettings }>(),
);

export const setUserSettingsSuccess: TypedActionCreatorWithProps<LoadedUserSettingsAction> =
  createAction(
    '[UserSettings/API] Set UserSettings Success',
    props<{ userSettings: UserSettingsResource }>(),
  );

export const setUserSettingsFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[UserSettings/API] Set UserSettings Failure',
  props<ApiErrorAction>(),
);
