/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { createStateResource, StateResource } from '@alfa-client/tech-shared';
import { Selectable } from '@alfa-client/ui';
import { isNull } from 'lodash-es';
import { UserProfileSearchFormService } from './user-profile.search.formservice';

@Component({
  selector: 'alfa-user-profile-search',
  templateUrl: './user-profile-search.component.html',
  styleUrls: ['./user-profile-search.component.scss'],
  providers: [UserProfileSearchFormService],
})
export class UserProfileSearchComponent {
  @Input() searchedUserProfiles: StateResource<Selectable[]> = createStateResource<Selectable[]>(
    [],
  );

  @Output() assignTo: EventEmitter<Selectable> = new EventEmitter();

  readonly formServiceClass = UserProfileSearchFormService;

  constructor(private formService: UserProfileSearchFormService) {}

  get form(): UntypedFormGroup {
    return this.formService.form;
  }

  assign(userProfile: Selectable): void {
    isNull(userProfile) ? this.setNoUserProfileFoundError() : this.assignTo.emit(userProfile);
  }

  setEmptySelectionError(): void {
    this.formService.setEmptyUserProfileError();
  }

  setNoUserProfileFoundError(): void {
    this.formService.setNoUserProfileFoundError();
  }

  onKeyUp(): void {
    this.formService.submit();
  }
}
