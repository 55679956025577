/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Action, createReducer, on } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { RouteData } from './navigation.models';

import * as NavigationActions from './navigation.actions';

export const NAVIGATION_FEATURE_KEY = 'NavigationState';

export interface NavigationPartialState {
  readonly [NAVIGATION_FEATURE_KEY]: NavigationState;
}

export interface NavigationState {
  currentRouteData: RouteData;
}

export const initialState: NavigationState = {
  currentRouteData: null,
};

const navigationReducer = createReducer(
  initialState,
  on(NavigationActions.updateCurrentRouteData, (state: NavigationState, action) =>
    handleChangedRoute(state, action.routeData),
  ),
);

function handleChangedRoute(state: NavigationState, newRouteData: RouteData): NavigationState {
  const hasRouteChanged: boolean = !isEqual(state.currentRouteData, newRouteData);
  return hasRouteChanged ? { ...state, currentRouteData: newRouteData } : state;
}

export function reducer(state: NavigationState, action: Action) {
  return navigationReducer(state, action);
}
