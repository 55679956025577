/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input } from '@angular/core';
import { StateResource } from '@alfa-client/tech-shared';
import { getUserName, UserProfileResource } from '@alfa-client/user-profile-shared';

@Component({
  selector: 'alfa-user-profile-name',
  templateUrl: './user-profile-name.component.html',
  styleUrls: ['./user-profile-name.component.scss'],
})
export class UserProfileNameComponent {
  @Input('userProfileStateResource')
  public set userProfile(userProfileStateResource: StateResource<UserProfileResource>) {
    this.userProfileStateResource = userProfileStateResource;
    this.userName = getUserName(userProfileStateResource.resource);
  }

  userProfileStateResource: StateResource<UserProfileResource>;
  userName: string;
}
