/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiRootRepository } from '../api-root.repository';

import * as ApiRootActions from './api-root.actions';

@Injectable()
export class ApiRootEffects {
  constructor(
    private readonly actions$: Actions,
    private apiRootService: ApiRootRepository,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiRootActions.loadApiRoot),
      switchMap(() =>
        this.apiRootService.loadApiRoot().pipe(
          map((apiRoot) => ApiRootActions.loadApiRootSuccess({ apiRoot })),
          catchError((apiError) => of(ApiRootActions.loadApiRootFailure({ apiError }))),
        ),
      ),
    ),
  );
}
