/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { LoadBinaryFileListSuccessProps } from '@alfa-client/binary-file-shared';
import {
  ApiErrorAction,
  TypedActionCreator,
  TypedActionCreatorWithProps,
} from '@alfa-client/tech-shared';
import { createAction, props } from '@ngrx/store';
import { PostfachNachrichtProps } from './postfach.actions.model';

export const startDownloadAsPdf: TypedActionCreator = createAction(
  '[Postfach] Start Download Nachrichten as pdf',
);
export const downloadAsPdfSuccess: TypedActionCreator = createAction(
  '[Postfach/API] Download Nachrichten as pdf Success',
);
export const downloadAsPdfFailed: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[Postfach/API] Download Nachrichten as pdf Failure',
  props<ApiErrorAction>(),
);

export const loadAttachmentList: TypedActionCreatorWithProps<PostfachNachrichtProps> = createAction(
  '[Postfach] Load attachmentList',
  props<PostfachNachrichtProps>(),
);
export const loadAttachmentListSuccess: TypedActionCreatorWithProps<LoadBinaryFileListSuccessProps> =
  createAction('[Postfach] Load attachmentList success', props<LoadBinaryFileListSuccessProps>());
export const loadAttachmentListFailure: TypedActionCreatorWithProps<ApiErrorAction> = createAction(
  '[Postfach] Load attachmentList failure',
  props<ApiErrorAction>(),
);
export const clearAttachmentList: TypedActionCreator = createAction(
  '[Postfach] Clear AttachmentList',
);
