/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { CloseIconComponent } from '../icons/close-icon/close-icon.component';
import { StampIconComponent } from '../icons/stamp-icon/stamp-icon.component';

@Component({
  selector: 'ods-bescheid-status-text',
  standalone: true,
  imports: [CommonModule, StampIconComponent, CloseIconComponent],
  template: ` <p class="flex gap-2 text-base font-medium text-text">
    <span class="flex items-center gap-2" *ngIf="bewilligt"
      ><ods-stamp-icon size="medium" class="fill-bewilligt" />Bewilligt am {{ dateText }}</span
    >
    <span class="flex items-center gap-2" *ngIf="!bewilligt"
      ><ods-close-icon class="fill-abgelehnt" />Abgelehnt am
      {{ dateText }}
    </span>
    <span
      *ngIf="hasBescheidDraft"
      class="flex items-center text-error"
      data-test-class="bescheid-status-draft"
      >(Entwurf)</span
    >
  </p>`,
})
export class BescheidStatusTextComponent {
  @Input({ required: true }) dateText: string = '';
  @Input() bewilligt: boolean = false;
  @Input() hasBescheidDraft: boolean = false;
}
