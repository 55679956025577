/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommandOrder } from './command.model';

export enum CommandErrorMessage {
  CONCURRENT_MODIFICATION = 'concurrent_modification',
}

export const COMMAND_ERROR_MESSAGES: { [code: string]: string } = {
  [CommandErrorMessage.CONCURRENT_MODIFICATION]:
    'Der Vorgang wurde zwischenzeitlich verändert und wurde neu geladen.',
};

export const CREATE_COMMAND_MESSAGE_BY_ORDER: { [order: string]: string } = {
  [CommandOrder.VORGANG_ANNEHMEN]: 'Der Vorgang wurde angenommen.',
  [CommandOrder.VORGANG_BEARBEITEN]: 'Der Vorgang wurde in die Bearbeitung übernommen.',
  [CommandOrder.VORGANG_BESCHEIDEN]: 'Der Vorgang wurde beschieden.',
  [CommandOrder.VORGANG_ZURUECKHOLEN]: 'Der Vorgang wurde zurückgeholt.',
  [CommandOrder.VORGANG_ZURUECKSTELLEN]: 'Der Vorgang wurde zurückgestellt.',
  [CommandOrder.VORGANG_ABSCHLIESSEN]: 'Der Vorgang wurde abgeschlossen.',
  [CommandOrder.VORGANG_WIEDEREROEFFNEN]: 'Der Vorgang wurde wiedereröffnet.',
  [CommandOrder.VORGANG_VERWERFEN]: 'Der Vorgang wurde verworfen.',
  [CommandOrder.VORGANG_ZUM_LOESCHEN_MARKIEREN]:
    'Für den Vorgang wurde eine Löschanforderung gestellt.',
  [CommandOrder.VORGANG_LOESCHEN]: 'Der Vorgang wurde gelöscht.',
  [CommandOrder.LOESCH_ANFORDERUNG_ZURUECKNEHMEN]:
    'Für den Vorgang wurde die Löschanforderung zurückgenommen.',
  [CommandOrder.ASSIGN_USER]: 'Dem Vorgang wurde eine bearbeitende Person zugewiesen.',
  [CommandOrder.CREATE_BESCHEID]: 'Es wurde ein Bescheid erstellt.',
  [CommandOrder.PROCESS_VORGANG]: 'Vorgang vorprüfen erfolgreich.',
};

export const LOESCH_ANFORDERUNG_ZURUECKNEHMEN_CONCURRENT_TO_DELETE_VORGANG_MESSAGE: string =
  'Der Vorgang wurde zwischenzeitlich gelöscht.';
