/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, OnInit } from '@angular/core';
import { ApiRootFacade, ApiRootLinkRel, ApiRootResource } from '@alfa-client/api-root-shared';
import { createEmptyStateResource, StateResource } from '@alfa-client/tech-shared';
import { VorgangListResource, VorgangListService } from '@alfa-client/vorgang-shared';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'alfa-vorgang-search-container',
  templateUrl: './vorgang-search-container.component.html',
  styleUrls: ['./vorgang-search-container.component.scss'],
})
export class VorgangSearchContainerComponent implements OnInit {
  public apiRoot$: Observable<StateResource<ApiRootResource>> = of(
    createEmptyStateResource<ApiRootResource>(),
  );
  public vorgangSearchPreviewList$: Observable<StateResource<VorgangListResource>> = of(
    createEmptyStateResource<VorgangListResource>(),
  );
  public searchString$: Observable<string>;

  readonly apiRootLinkRel = ApiRootLinkRel;

  constructor(
    private apiRootFacade: ApiRootFacade,
    private vorgangListService: VorgangListService,
  ) {}

  ngOnInit(): void {
    this.apiRoot$ = this.apiRootFacade.getApiRoot();
    this.vorgangSearchPreviewList$ = this.vorgangListService.getSearchPreviewList();
    this.searchString$ = this.vorgangListService.getSearchString();
  }

  clearVorgangSearchPreviewList(): void {
    this.vorgangListService.clearSearchPreviewList();
    this.vorgangListService.clearSearchString();
  }
}
