/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export enum ValidationMessageCode {
  FIELD_FILE_SIZE_EXCEEDED = 'validation_field_file_size_exceeded',
  FIELD_EMPTY = 'validation_field_empty',
  FIELD_SIZE = 'validation_field_size',
  FIELD_FILE_CONTENT_TYPE_INVALID = 'validation_field_file_content_type_invalid',
  FIELD_MIN_SIZE = 'validation_field_min_size',
  FIELD_MAX_SIZE = 'validation_field_max_size',
  FIELD_DATE_PAST = 'validation_field_date_past',
  FIELD_INVALID = 'validation_field_invalid',
  FIELD_DATE_FORMAT_INVALID = 'validation_field_date_format_invalid',
  FIELD_ASSIGN_BEARBEITER_NOT_EXIST = 'fe_only_validation_bearbeiter_not_exist',
}

export const VALIDATION_MESSAGES: { [code: string]: string } = {
  [ValidationMessageCode.FIELD_EMPTY]: 'Bitte {field} ausfüllen',
  [ValidationMessageCode.FIELD_MAX_SIZE]: '{field} darf höchstens {max} Zeichen enthalten',
  [ValidationMessageCode.FIELD_MIN_SIZE]: '{field} muss aus mindestens {min} Zeichen bestehen',
  [ValidationMessageCode.FIELD_SIZE]:
    '{field} muss mindestens {min} und darf höchstens {max} Zeichen enthalten',
  [ValidationMessageCode.FIELD_DATE_PAST]: 'Das Datum für {field} muss in der Zukunft liegen',
  [ValidationMessageCode.FIELD_INVALID]: 'Bitte {field} korrekt ausfüllen',
  [ValidationMessageCode.FIELD_FILE_SIZE_EXCEEDED]:
    'Anhänge größer {max}{unit} können nicht hinzugefügt werden.',

  fe_only_validation_bearbeiter_not_exist: 'Der Bearbeiter existiert nicht',
  [ValidationMessageCode.FIELD_DATE_FORMAT_INVALID]: 'Geben Sie ein gültiges Datum ein',
  [ValidationMessageCode.FIELD_FILE_CONTENT_TYPE_INVALID]:
    'Erlaubte Dateiendungen: pdf, jpg, png, jpeg',
};
