/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
  StateResource,
} from '@alfa-client/tech-shared';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { UserSettingsResource } from '../user-settings.model';
import * as UserSettingsActions from './user-settings.actions';
import { ApiErrorAction, LoadedUserSettingsAction } from './user-settings.actions';

export const SETTINGS_FEATURE_KEY = 'UserSettingsState';

export interface UserSettingsPartialState {
  readonly [SETTINGS_FEATURE_KEY]: UserSettingsState;
}

export interface UserSettingsState {
  userSettings: StateResource<UserSettingsResource>;
}

export const initialUserSettingsState: UserSettingsState = {
  userSettings: createEmptyStateResource(),
};

const reducer: ActionReducer<UserSettingsState, Action> = createReducer(
  initialUserSettingsState,
  on(
    UserSettingsActions.loadUserSettings,
    (state: UserSettingsState): UserSettingsState => ({
      ...state,
      userSettings: { ...state.userSettings, loading: true },
    }),
  ),
  on(
    UserSettingsActions.loadUserSettingsSuccess,
    (state: UserSettingsState, action: LoadedUserSettingsAction) => ({
      ...state,
      userSettings: createStateResource<UserSettingsResource>(action.userSettings),
    }),
  ),
  on(
    UserSettingsActions.loadUserSettingsFailure,
    (state: UserSettingsState, action: ApiErrorAction) => ({
      ...state,
      userSettings: createErrorStateResource<UserSettingsResource>(action.apiError),
    }),
  ),

  on(
    UserSettingsActions.setUserSettings,
    (state: UserSettingsState): UserSettingsState => ({
      ...state,
      userSettings: { ...state.userSettings, loading: true },
    }),
  ),
  on(
    UserSettingsActions.setUserSettingsSuccess,
    (state: UserSettingsState, action: LoadedUserSettingsAction) => ({
      ...state,
      userSettings: createStateResource<UserSettingsResource>(action.userSettings),
    }),
  ),
  on(
    UserSettingsActions.setUserSettingsFailure,
    (state: UserSettingsState, action: ApiErrorAction) => ({
      ...state,
      userSettings: createErrorStateResource<UserSettingsResource>(action.apiError),
    }),
  ),
);

export function userSettingsReducer(state: UserSettingsState, action: Action): UserSettingsState {
  return reducer(state, action);
}
