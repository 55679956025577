<!--

    Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<div class="relative flex h-full flex-1">
  <input
    type="radio"
    class="peer absolute left-0 top-0 z-0 h-0 w-0 opacity-0"
    id="{{ name }}_{{ value }}"
    name="{{ name }}"
    attr.name="{{ name }}"
    formControlName="{{ name }}"
    value="{{ value }}"
  />
  <label
    for="{{ name }}_{{ value }}"
    [ngClass]="getClass(variant)"
    class="z-10 flex flex-1 cursor-pointer items-center justify-center gap-2 rounded-lg text-center text-base peer-focus-visible:outline peer-focus-visible:outline-2 peer-focus-visible:outline-offset-2 peer-focus-visible:outline-ozgblue-800"
  >
    <ng-content />
    <p class="h-fit" [ngClass]="{ 'w-full': fullWidthText }">{{ label }}</p>
  </label>
</div>
