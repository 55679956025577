/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { ExclamationIconComponent } from '../exclamation-icon/exclamation-icon.component';
import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-user-icon',
  standalone: true,
  imports: [CommonModule, ExclamationIconComponent],
  template: `
    <svg
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [ngClass]="[twMerge(iconVariants({ size }), 'fill-ozggray-300', class)]"
    >
      <path
        d="M56 0.970734C25.6239 0.970734 0.970886 25.6239 0.970886 56C0.970886 86.3761 25.6239 111.029 56 111.029C86.3761 111.029 111.029 86.3761 111.029 56C111.029 25.6239 86.3761 0.970734 56 0.970734ZM56 17.4795C65.135 17.4795 72.5087 24.8534 72.5087 33.9881C72.5087 43.1232 65.135 50.4969 56 50.4969C46.8652 50.4969 39.4912 43.1232 39.4912 33.9881C39.4912 24.8534 46.8652 17.4795 56 17.4795ZM56 95.621C42.2428 95.621 30.0814 88.5772 22.9825 77.9014C23.1477 66.9506 44.9943 60.9526 56 60.9526C66.9508 60.9526 88.8525 66.9506 89.0175 77.9014C81.9189 88.5772 69.7575 95.621 56 95.621Z"
      />
    </svg>
  `,
})
export class UserIconComponent {
  @Input() variant: 'user' | 'initials' = 'user';
  @Input() size: IconVariants['size'] = 'xxl';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
