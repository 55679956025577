/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-save-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-primary', class)]"
    aria-hidden="true"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M21.3333 12V21.3333H2.66667V12H0V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V12H21.3333ZM13.3333 12.8933L16.7867 9.45333L18.6667 11.3333L12 18L5.33333 11.3333L7.21333 9.45333L10.6667 12.8933V0H13.3333V12.8933Z"
    />
  </svg>`,
})
export class SaveIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
