/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { StateResource } from '@alfa-client/tech-shared';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { UserSettingsResource } from '../user-settings.model';
import { SETTINGS_FEATURE_KEY, UserSettingsState } from './user-settings.reducer';

export const getUserSettingsState: MemoizedSelector<object, UserSettingsState> =
  createFeatureSelector<UserSettingsState>(SETTINGS_FEATURE_KEY);

export const userSettings: MemoizedSelector<
  UserSettingsState,
  StateResource<UserSettingsResource>
> = createSelector(getUserSettingsState, (state: UserSettingsState) => state.userSettings);
