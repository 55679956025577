/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Resource } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { StateResource } from './resource.util';

export interface ListResourceServiceConfig<B> {
  baseResource: Observable<StateResource<B>>;
  listLinkRel: LinkRelationName;
  listResourceListLinkRel: LinkRelationName;
  createLinkRel?: LinkRelationName;
}

export interface CreateResourceData<T> {
  resource: T;
  linkRel: string;
  toCreate: any;
}

export interface SaveResourceData<T> {
  resource: T;
  linkRel: string;
  toSave: any;
}

export interface ListItemResource extends Resource {}
export declare type LinkRelationName = string;

export interface ResourceServiceConfig<B> {
  resource: Observable<StateResource<B>>;
  getLinkRel: LinkRelationName;
  delete?: { linkRel: LinkRelationName; order?: string };
  edit?: { linkRel: LinkRelationName; order?: string };
}

export interface SearchResourceServiceConfig<B> {
  baseResource: Observable<B>;
  searchLinkRel: LinkRelationName;
}
