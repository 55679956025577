/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { has } from 'lodash-es';
import { MessageCode } from '../message-code';
import { ApiError } from '../tech.model';

export function isApiError(error: unknown): boolean {
  return has(error, 'issues[0]');
}

export function isNotApiError(error: unknown): boolean {
  return !isApiError(error);
}

export function isServiceUnavailableMessageCode(error: unknown): boolean {
  if (isNotApiError(error)) {
    return false;
  }

  return (error as ApiError).issues[0].messageCode === MessageCode.SERVICE_UNAVAILABLE;
}

export function isResourceNotFoundError(error: unknown): boolean {
  if (isNotApiError(error)) {
    return false;
  }

  return (error as ApiError).issues[0].messageCode === MessageCode.RESOURCE_NOT_FOUND;
}

export function getApiErrorFromHttpErrorResponse(httpErrorResponse: HttpErrorResponse): ApiError {
  return httpErrorResponse?.error?.error;
}
