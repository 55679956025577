<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<div mat-dialog-title data-test-id="dialog-header">
  <span>{{ data.title }}</span>
  <div class="button-bar">
    <button
      mat-icon-button
      data-test-id="min-max-button"
      (click)="minimize()"
      [ngClass]="{ minimized: isMinimized }"
      aria-label="Nachricht minimieren"
    >
      <mat-icon>minimize</mat-icon>
    </button>

    <button
      mat-icon-button
      data-test-id="close-button"
      [mat-dialog-close]="true"
      aria-label="Nachricht schließen"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content [ngClass]="{ minimized: isMinimized }" data-test-id="dialog-content">
  <ng-container *ngComponentOutlet="data.component; injector: componentInjector"></ng-container>
</mat-dialog-content>
