/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { isNull } from 'lodash-es';
import { HINT_FEATURE_KEY, HintState } from './hint.reducer';

import * as HintUtil from '../hint.util';

export const getHintState: MemoizedSelector<object, HintState> =
  createFeatureSelector<HintState>(HINT_FEATURE_KEY);

export const hasUserNoOrganisationsEinheitIdHint: MemoizedSelector<HintState, boolean> =
  createSelector(getHintState, (state: HintState) => {
    if (isNull(state.hintList.resource)) {
      return false;
    }
    return HintUtil.hasUserNoOrganisationsEinheitIdHint(state.hintList.resource);
  });
