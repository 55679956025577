<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<header data-test-id="header">
  <div class="flex self-center sm:ml-3 lg:min-w-60">
    <alfa-header-logo></alfa-header-logo>
  </div>
  <div class="grow sm:order-2 sm:mr-4 lg:w-full">
    <alfa-vorgang-search-container></alfa-vorgang-search-container>
  </div>
  <div class="flex flex-1 items-center justify-end self-end text-ozggray-800 dark:text-ozggray-300 sm:order-3 sm:self-center">
    <common-accessibility-button
      *ngIf="apiRootStateResource?.resource.barrierefreiheitUrl"
      [url]="apiRootStateResource.resource.barrierefreiheitUrl"
      class="mr-3"
      linkTestId="accessibility-button"
    />
    <alfa-help-menu [apiRootStateResource]="apiRootStateResource" data-test-id="help-menu"></alfa-help-menu>
    <alfa-user-settings-container data-test-id="user-settings"></alfa-user-settings-container>
    <alfa-user-profile-in-header-container data-test-id="current-user"></alfa-user-profile-in-header-container>
  </div>
</header>
