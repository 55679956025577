<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<h1 mat-dialog-title>
  <mat-icon data-test-id="error-icon">error_outline</mat-icon>
  <span data-test-id="error-header">Es ist ein Fehler aufgetreten ...</span>
</h1>
<mat-dialog-content>
  <p data-test-id="error-message" class="error-message">
    Wir sind nicht ganz sicher, was schiefgelaufen ist. Der Fehler
    <span *ngIf="showId()">(ID: {{ data.issues[0].exceptionId }})</span> wurde unserem Support
    gemeldet. Bitte starten sie die Anwendung neu.
  </p>
  <a href="/" tabindex="-1" class="button">
    <ozgcloud-stroked-button-with-spinner
      icon="refresh"
      text="Neu starten"
    ></ozgcloud-stroked-button-with-spinner>
  </a>
  <ozgcloud-stroked-button-with-spinner
    icon="logout"
    text="Abmelden"
    data-test-id="logout-button"
    (clickEmitter)="authService.logOut()"
  >
  </ozgcloud-stroked-button-with-spinner>
</mat-dialog-content>
