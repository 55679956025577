/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { isNotUndefined } from '@alfa-client/tech-shared';
import { DialogService } from '@alfa-client/ui';
import { ConnectionTimeoutRetryDialogComponent } from '../http-error-dialog/connection-timeout-retry-dialog/connection-timeout-retry-dialog.component';
import { ConnectionTimeoutRetryFailDialogComponent } from '../http-error-dialog/connection-timeout-retry-fail-dialog/connection-timeout-retry-fail-dialog.component';

export class RetryInTimeDialog {
  public static ID = 'RETRY_DIALOG_ID';

  readonly RETRY_DIALOG_CONFIG = {
    disableClose: true,
    id: RetryInTimeDialog.ID,
  };

  private readonly retryTime = 300000; // = 5min
  private startTime: number | undefined;

  constructor(private dialogService: DialogService) {}

  public show(): void {
    this.startTimer();
    this.open();
  }

  private startTimer(): void {
    this.startTime = new Date().getTime();
  }

  private open(): void {
    this.dialogService.open(ConnectionTimeoutRetryDialogComponent, this.RETRY_DIALOG_CONFIG);
  }

  public shouldRetry(): boolean {
    return !this.isTimeOver();
  }

  private isTimeOver(): boolean {
    if (isNotUndefined(this.startTime)) {
      return new Date().getTime() > this.startTime + this.retryTime;
    }
    return true;
  }

  public finish(): void {
    this.dialogService.closeById(RetryInTimeDialog.ID);
    this.dialogService.open(ConnectionTimeoutRetryFailDialogComponent);
  }
}
