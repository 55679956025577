/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { TechSharedModule } from '@alfa-client/tech-shared';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ods-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TechSharedModule],
  template: `
    <div>
      <div class="relative flex items-start gap-3 text-start">
        <input
          type="checkbox"
          class="peer box-border size-5 shrink-0 appearance-none rounded-sm border bg-whitetext outline outline-2 outline-offset-2 outline-transparent hover:border-2 focus-visible:border-background-200 disabled:border-disabled-dark disabled:bg-disabled disabled:hover:border"
          [ngClass]="
            hasError ?
              'border-error hover:border-error focus-visible:outline-error'
            : 'border-primary hover:border-primary-hover focus-visible:outline-focus'
          "
          [formControl]="fieldControl"
          [attr.id]="inputId"
          [attr.disabled]="disabled ? true : null"
          [attr.data-test-id]="(label | convertForDataTest) + '-checkbox-editor'"
        />
        <label class="leading-5 text-text" [attr.for]="inputId">{{ label }}</label>
        <svg
          viewBox="0 0 12 9"
          xmlns="http://www.w3.org/2000/svg"
          class="pointer-events-none absolute hidden size-5 p-1 outline-none peer-checked:block"
          [ngClass]="hasError ? 'fill-error' : 'fill-primary focus-visible:fill-focus'"
        >
          <path
            d="M3.81353 7.10067L0.968732 4.30201L0 5.24832L3.81353
          9L12 0.946309L11.0381 0L3.81353 7.10067Z"
          />
        </svg>
      </div>
      <ng-content select="[error]" />
    </div>
  `,
})
export class CheckboxComponent {
  @Input() fieldControl: FormControl = new FormControl(false);
  @Input() inputId: string;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() hasError: boolean = false;
}
