/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash-es';
import { InvalidParam, ProblemDetail } from '../tech.model';
import { EMPTY_STRING } from '../tech.util';
import { getMessageForInvalidParam } from '../validation/tech.validation.util';

@Pipe({ name: 'convertProblemDetailToErrorMessages' })
export class ConvertProblemDetailToErrorMessagesPipe implements PipeTransform {
  transform(value: ProblemDetail) {
    if (isNil(value)) {
      return [];
    }
    return value.invalidParams.map((invalidParam: InvalidParam) =>
      getMessageForInvalidParam(EMPTY_STRING, invalidParam),
    );
  }
}
