/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Action, ActionCreator } from '@ngrx/store';
import { ResourceUri } from '@ngxp/rest';
import { ApiError } from '../tech.model';

export const EMPTY_ACTION: Action = {} as Action;

export interface TypedActionCreatorWithProps<T>
  extends ActionCreator<string, (props: T) => T & Action<string>> {}
export interface TypedActionCreator extends ActionCreator<string, () => Action<string>> {}

export interface ApiErrorAction {
  //TODO rename ApiErrorProps
  apiError: ApiError;
}

export interface ResourceUriProps {
  resourceUri: ResourceUri;
}
