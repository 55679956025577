/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef } from '@angular/core';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class OzgcloudDialogService {
  readonly WIZARD_DIALOG_CONFIG: DialogConfig = {
    width: '1000px',
    restoreFocus: false,
    disableClose: true,
  };

  readonly GREY_BLUR_CONFIG: DialogConfig = {
    backdropClass: ['backdrop-blur-1', 'bg-grayBackdrop'],
    disableClose: true,
  };

  constructor(private dialog: Dialog) {}

  public openWizard<C, D, R = unknown>(component: ComponentType<C>, data?: D): DialogRef<R> {
    return this.openDialog<C, R>(component, this.buildDialogConfigWithData<D>(data, this.WIZARD_DIALOG_CONFIG));
  }

  public open<C, D = unknown, R = unknown>(component: ComponentType<C>, data?: D): DialogRef<R> {
    return this.openDialog(component, this.buildDialogConfigWithData(data));
  }

  public openFullScreenDialog<T, D = unknown>(
    component: ComponentType<T>,
    viewContainerRef: ViewContainerRef,
    data?: D,
  ): DialogRef<T> {
    return this.openInCallingComponentContext(component, viewContainerRef, data, this.GREY_BLUR_CONFIG);
  }

  public openInCallingComponentContext<C, D = unknown, R = unknown>(
    component: ComponentType<C>,
    viewContainerRef: ViewContainerRef,
    data?: D,
    dialogConfig?: DialogConfig,
  ): DialogRef<R> {
    return this.openDialog<C, R>(component, this.buildDialogConfigWithData(data, { viewContainerRef, ...dialogConfig }));
  }

  private buildDialogConfigWithData<D>(data: D, dialogConfig?: DialogConfig): DialogConfig | null {
    if (isNil(data)) {
      return dialogConfig;
    }
    return { ...dialogConfig, data };
  }

  private openDialog<C, R = unknown>(component: ComponentType<C>, dialogConfig?: DialogConfig): DialogRef<R, C> {
    return this.dialog.open<R, unknown, C>(component, dialogConfig);
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }
}
