import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';
import { iconVariants, IconVariants } from '../iconVariants';

@Component({
  selector: 'ods-accessibility-icon',
  standalone: true,
  imports: [CommonModule],
  template: `<svg
    viewBox="0 0 26 26"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-neutral-500 dark:fill-neutral-400', class)]"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <rect x="2" y="2" width="22" height="22" rx="11" />
    <path
      d="M13 8.87508C12.6828 8.87508 12.3726 8.781 12.1088 8.60473C11.845 8.42846 11.6394 8.17793 11.518 7.8848C11.3966 7.59168 11.3648 7.26914 11.4267 6.95796C11.4886 6.64678 11.6414 6.36095 11.8657 6.1366C12.0901 5.91225 12.3759 5.75947 12.6871 5.69757C12.9983 5.63568 13.3208 5.66744 13.6139 5.78886C13.907 5.91027 14.1576 6.11588 14.3339 6.37969C14.5101 6.64349 14.6042 6.95364 14.6042 7.27092C14.6038 7.69623 14.4346 8.10399 14.1339 8.40473C13.8331 8.70547 13.4253 8.87463 13 8.87508Z"
      class="fill-whitetext"
    />
    <path
      d="M18.0417 8.898L18.0288 8.90144L18.0168 8.90516C17.9881 8.91318 17.9595 8.92178 17.9308 8.93066C17.3977 9.08706 14.8105 9.81638 12.9877 9.81638C11.2939 9.81638 8.94064 9.18618 8.18783 8.97219C8.1129 8.94322 8.03639 8.91855 7.95866 8.89829C7.41439 8.75506 7.04199 9.30792 7.04199 9.81323C7.04199 10.3137 7.49173 10.552 7.94577 10.723V10.731L10.6734 11.583C10.9521 11.6898 11.0266 11.799 11.063 11.8935C11.1813 12.1969 11.0868 12.7976 11.0533 13.0072L10.8871 14.2963L9.96501 19.3434C9.96215 19.3572 9.95957 19.3712 9.95728 19.3855L9.95069 19.4219C9.88423 19.8845 10.224 20.3334 10.8674 20.3334C11.4288 20.3334 11.6766 19.9458 11.784 19.4185C11.784 19.4185 12.5861 14.9047 12.9871 14.9047C13.3882 14.9047 14.2143 19.4185 14.2143 19.4185C14.3218 19.9458 14.5695 20.3334 15.131 20.3334C15.7761 20.3334 16.1158 19.8825 16.0477 19.4185C16.0417 19.3789 16.0345 19.34 16.0259 19.3022L15.0912 14.2969L14.9253 13.0078C14.8053 12.257 14.9018 12.0089 14.9345 11.9508L14.9368 11.9465C14.9677 11.8892 15.1087 11.7609 15.4375 11.6374L17.995 10.7434C18.0107 10.7392 18.0262 10.7342 18.0414 10.7285C18.4998 10.5566 18.9581 10.3188 18.9581 9.81381C18.9581 9.30878 18.586 8.75506 18.0417 8.898Z"
      class="fill-whitetext"
    />
  </svg>`,
})
export class AccessibilityIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = '';

  readonly iconVariants = iconVariants;
  readonly twMerge = twMerge;
}
