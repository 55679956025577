/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { StateResource, createEmptyStateResource } from '@alfa-client/tech-shared';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Resource } from '@ngxp/rest';
import { isNil } from 'lodash-es';

@Component({
  selector: 'ozgcloud-icon-button-with-spinner',
  templateUrl: './icon-button-with-spinner.component.html',
  styleUrls: ['./icon-button-with-spinner.component.scss'],
})
export class IconButtonWithSpinnerComponent {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() matMenuTriggerFor: string;
  @Input() stateResource: StateResource<Resource>;
  @Input() toolTip: string = '';
  @Input() showSpinner: boolean = false;

  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  get isDisabled(): boolean {
    return this.showSpinner || this.getStateResource().loading;
  }

  getStateResource(): StateResource<Resource> {
    return isNil(this.stateResource) ? createEmptyStateResource<Resource>() : this.stateResource;
  }
}
