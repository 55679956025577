/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { createEmptyStateResource } from '@alfa-client/tech-shared';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { isUndefined } from 'lodash-es';
import { CommandOrder } from '../command.model';
import { COMMAND_FEATURE_KEY, CommandState } from './command.reducer';

const getCommandState: MemoizedSelector<object, CommandState> =
  createFeatureSelector<CommandState>(COMMAND_FEATURE_KEY);

export const commandByOrderMap: MemoizedSelector<CommandState, any> = createSelector(
  getCommandState,
  (state: CommandState) => state.commandByOrderMap,
);
export const commandByOrder = (order: CommandOrder) =>
  createSelector(commandByOrderMap, (commandByOrderMapInState: any) =>
    isUndefined(commandByOrderMapInState[order]) ?
      createEmptyStateResource()
    : commandByOrderMapInState[order],
  );
