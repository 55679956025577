/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse } from '@angular/common/http';
import { getUrl, hasLink, Resource, ResourceUri } from '@ngxp/rest';
import { isEqual, isNull } from 'lodash-es';
import { BehaviorSubject, catchError, combineLatest, filter, first, map, Observable, of, startWith, tap, throwError } from 'rxjs';
import { isUnprocessableEntity } from '../http.util';
import { HttpError } from '../tech.model';
import { isNotNull } from '../tech.util';
import { ResourceServiceConfig } from './resource.model';
import { ResourceRepository } from './resource.repository';
import { mapToFirst, mapToResource } from './resource.rxjs.operator';
import {
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
  isInvalidResourceCombination,
  isLoadingRequired,
  isStateResoureStable,
  StateResource,
} from './resource.util';

/**
 * B = Type of baseresource
 * T = Type of the resource which is working on
 */
export abstract class ResourceService<B extends Resource, T extends Resource> {
  readonly stateResource: BehaviorSubject<StateResource<T>> = new BehaviorSubject(createEmptyStateResource());

  configResource: B = null;

  constructor(
    protected config: ResourceServiceConfig<B>,
    protected repository: ResourceRepository,
  ) {}

  public get(): Observable<StateResource<T>> {
    return combineLatest([this.stateResource.asObservable(), this.getConfigResource()]).pipe(
      tap(([stateResource, configResource]) => this.handleResourceChanges(stateResource, configResource)),
      filter(([stateResource]) => !isInvalidResourceCombination(stateResource, this.configResource)),
      mapToFirst<T, B>(),
      startWith(createEmptyStateResource<T>(true)),
    );
  }

  private getConfigResource(): Observable<B> {
    return this.config.resource.pipe(
      filter((configStateResource: StateResource<B>) => !configStateResource.loading && !configStateResource.reload),
      mapToResource<B>(),
    );
  }

  handleResourceChanges(stateResource: StateResource<T>, configResource: B): void {
    if (!isEqual(this.configResource, configResource)) {
      this.handleConfigResourceChanges(stateResource, configResource);
    } else if (this.shouldLoadResource(stateResource, configResource)) {
      this.loadResource(configResource);
    }
  }

  handleConfigResourceChanges(stateResource: StateResource<T>, configResource: B) {
    this.configResource = configResource;
    if (isStateResoureStable(stateResource)) {
      this.updateStateResourceByConfigResource(stateResource, configResource);
    }
  }

  updateStateResourceByConfigResource(stateResource: StateResource<T>, configResource: B): void {
    if (this.shouldClearStateResource(stateResource, configResource)) {
      this.clearResource();
    } else if (this.hasGetLink(configResource)) {
      this.loadResource(configResource);
    }
  }

  shouldClearStateResource(stateResource: StateResource<T>, configResource: B): boolean {
    return (isNull(configResource) || this.hasNotGetLink(configResource)) && !this.isStateResourceEmpty(stateResource);
  }

  private hasNotGetLink(configResource: B): boolean {
    return !this.hasGetLink(configResource);
  }

  private isStateResourceEmpty(stateResource: StateResource<T>): boolean {
    return isEqual(stateResource, createEmptyStateResource());
  }

  private clearResource(): void {
    this.stateResource.next(createEmptyStateResource());
  }

  hasGetLink(configResource: B): boolean {
    return isNotNull(configResource) && hasLink(configResource, this.config.getLinkRel);
  }

  shouldLoadResource(stateResource: StateResource<T>, configResource: B): boolean {
    return isNotNull(configResource) && isLoadingRequired(stateResource);
  }

  loadResource(configResource: B): void {
    this.doLoadResource(getUrl(configResource, this.config.getLinkRel));
  }

  public loadByResourceUri(resourceUri: ResourceUri): void {
    this.doLoadResource(resourceUri);
  }

  doLoadResource(resourceUri: ResourceUri): void {
    this.setStateResourceLoading();
    this.repository
      .getResource(resourceUri)
      .pipe(first())
      .subscribe((loadedResource: T) => this.updateStateResource(loadedResource));
  }

  setStateResourceLoading(): void {
    this.stateResource.next({ ...createEmptyStateResource(true), reload: false });
  }

  updateStateResource(resource: T): void {
    this.stateResource.next(createStateResource(resource));
  }

  public save(toSave: unknown): Observable<StateResource<T>> {
    const previousResource: T = this.stateResource.value.resource;
    return this.handleSave(this.doSave(previousResource, toSave));
  }

  public patch(toPatch: unknown): Observable<StateResource<T>> {
    const previousResource: T = this.stateResource.value.resource;
    return this.handleSave(this.doPatch(previousResource, toPatch));
  }

  private handleSave(response$: Observable<T>): Observable<StateResource<T>> {
    return response$.pipe(
      tap((loadedResource: T) => this.stateResource.next(createStateResource(loadedResource))),
      map(() => this.stateResource.value),
      catchError((errorResponse: HttpErrorResponse) => this.handleError(errorResponse)),
    );
  }

  handleError(errorResponse: HttpErrorResponse): Observable<StateResource<T>> {
    if (isUnprocessableEntity(errorResponse.status)) {
      return of(createErrorStateResource((<any>errorResponse) as HttpError));
    }
    return throwError(() => errorResponse);
  }

  abstract doSave(resource: T, toSave: unknown): Observable<T>;

  abstract doPatch(resource: T, toPatch: unknown): Observable<T>;

  public refresh(): void {
    this.stateResource.next({ ...this.stateResource.value, reload: true });
  }

  public existResource(): Observable<boolean> {
    return this.stateResource.asObservable().pipe(mapToResource<T>(), map(isNotNull));
  }

  public selectResource(): Observable<StateResource<T>> {
    return this.stateResource.asObservable();
  }
}
