/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import {
  BinaryFileListResource,
  loadBinaryFileList,
  LoadBinaryFileListProps,
} from '@alfa-client/binary-file-shared';
import { ApiError, ApiErrorAction, getMessageCode, MessageCode } from '@alfa-client/tech-shared';
import { SnackBarService } from '@alfa-client/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Messages } from 'libs/ui/src/lib/ui/messages';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PostfachMailLinkRel } from '../postfach.linkrel';
import { PostfachMailResource } from '../postfach.model';
import { PostfachNachrichtProps } from './postfach.actions.model';

import * as PostfachActions from './postfach.actions';

@Injectable()
export class PostfachEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly snackBarService: SnackBarService,
  ) {}

  downloadAsPdfFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PostfachActions.downloadAsPdfFailed),
        tap((action: ApiErrorAction) => {
          if (getMessageCode(action.apiError) == MessageCode.USER_MANAGER_SERVICE_UNAVAILABLE) {
            this.snackBarService.showError(Messages.HTTP_USER_MANAGER_SERVICE_UNAVAILABLE);
          }
        }),
      ),
    { dispatch: false },
  );

  loadAttachmentList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostfachActions.loadAttachmentList),
      switchMap((props: PostfachNachrichtProps) =>
        of(loadBinaryFileList(this.createLoadAttachmentListProps(props.postfachNachricht))),
      ),
    ),
  );

  createLoadAttachmentListProps(postfachNachricht: PostfachMailResource): LoadBinaryFileListProps {
    return {
      resource: postfachNachricht,
      linkRel: PostfachMailLinkRel.ATTACHMENTS,
      successAction: (binaryFileList: BinaryFileListResource) =>
        PostfachActions.loadAttachmentListSuccess({ binaryFileList }),
      failureAction: (apiError: ApiError) =>
        PostfachActions.loadAttachmentListFailure({ apiError }),
    };
  }
}
