/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input, OnInit } from '@angular/core';
import { KommentarLinkRel, KommentarResource } from '@alfa-client/kommentar-shared';
import { StateResource } from '@alfa-client/tech-shared';
import { UserProfileResource, UserProfileService } from '@alfa-client/user-profile-shared';
import { hasLink } from '@ngxp/rest';
import { Observable } from 'rxjs';

@Component({
  selector: 'alfa-user-profile-in-kommentar-container',
  templateUrl: './user-profile-in-kommentar-container.component.html',
  styleUrls: ['./user-profile-in-kommentar-container.component.scss'],
})
export class UserProfileInKommentarContainerComponent implements OnInit {
  @Input() kommentar: KommentarResource;

  userProfileStateResource$: Observable<StateResource<UserProfileResource>>;

  constructor(private userProfileService: UserProfileService) {}

  ngOnInit(): void {
    if (this.kommentar && hasLink(this.kommentar, KommentarLinkRel.CREATED_BY)) {
      this.userProfileStateResource$ = this.userProfileService.getAssignedUserProfile(
        this.kommentar,
        KommentarLinkRel.CREATED_BY,
      );
    }
  }
}
