/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { ApiRootLinkRel, ApiRootProps } from '@alfa-client/api-root-shared';
import { getApiErrorFromHttpErrorResponse } from '@alfa-client/tech-shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { hasLink } from '@ngxp/rest';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { HintRepository } from './hint.repository';

import * as ApiRootActions from '../../../../api-root-shared/src/lib/+state/api-root.actions';
import * as HintActions from '../../../../hint-shared/src/lib/+state/hint.actions';

@Injectable()
export class HintEffects {
  constructor(
    private readonly actions$: Actions,
    private repository: HintRepository,
  ) {}

  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiRootActions.loadApiRootSuccess),
      filter((props: ApiRootProps) => hasLink(props.apiRoot, ApiRootLinkRel.HINTS)),
      switchMap((props: ApiRootProps) =>
        this.repository.loadAll(props.apiRoot).pipe(
          map((hintList) => HintActions.loadHintListSuccess({ hintList })),
          catchError((apiError) =>
            of(
              HintActions.loadHintListFailed({
                apiError: getApiErrorFromHttpErrorResponse(apiError),
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
