/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  EMPTY_STRING,
  getFirstLetter,
  getStringValue,
  isNotEmpty,
  isNotNull,
} from '@alfa-client/tech-shared';
import { isNil, isNull } from 'lodash-es';
import { UserProfileResource } from './user-profile.model';

export const NO_NAME_MESSAGE: string = 'Benutzer ohne hinterlegtem Namen';
export const UNKNOWN_USER: string = 'Unbekannter Benutzer';

export function existsName(userProfile: UserProfileResource): boolean {
  return (
    (isNotEmpty(userProfile.firstName) && isNotNull(userProfile.firstName)) ||
    (isNotEmpty(userProfile.lastName) && isNotNull(userProfile.lastName))
  );
}

export function getUserName(userProfile: UserProfileResource): string {
  if (isNil(userProfile)) {
    return UNKNOWN_USER;
  }
  if (existsName(userProfile)) {
    return `${getStringValue(userProfile.firstName)} ${getStringValue(userProfile.lastName)}`.trim();
  }
  return NO_NAME_MESSAGE;
}

export function getUserNameInitials(userProfile: UserProfileResource): string {
  return `${getFirstLetterUpperCase(userProfile.firstName)}${getFirstLetterUpperCase(userProfile.lastName)}`;
}

function getFirstLetterUpperCase(value: string) {
  const firstLetter: string = getFirstLetter(value);
  return isNull(firstLetter) ? EMPTY_STRING : firstLetter.toUpperCase();
}
