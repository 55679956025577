<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<button
  *ngIf="getNumberOfMenuItems() > 0"
  mat-fab
  extended
  [matMenuTriggerFor]="helpMenu.matMenu"
  data-test-id="help-menu-button"
>
  <div class="flex items-center text-ozggray-800 dark:text-ozggray-300">
    <ozgcloud-icon class="mr-1" icon="help_outline"></ozgcloud-icon>
    <div>Hilfe</div>
  </div>
</button>
<ozgcloud-menu #helpMenu>
  <ozgcloud-menu-item
    *ngIf="apiRootStateResource?.resource | hasLink: apiRootLinkRel.DOCUMENTATIONS"
    headline="Benutzerleitfaden"
    text="Alle Funktionen der Allgemeinen Fachanwendung (Alfa) erklärt."
    icon="pdf_file"
    #benutzerleitfadenMenuItem
  >
    <alfa-documentation
      data-test-id="documentations-component"
      [menuTitle]="benutzerleitfadenMenuItem.headline"
      [url]="apiRootStateResource.resource | getUrl: apiRootLinkRel.DOCUMENTATIONS"
    >
    </alfa-documentation>
  </ozgcloud-menu-item>
</ozgcloud-menu>
