/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VariantProps, cva } from 'class-variance-authority';

import { IconVariants } from '../icons/iconVariants';
import { SpinnerIconComponent } from '../icons/spinner-icon/spinner-icon.component';

export const buttonVariants = cva(
  [
    'flex items-center gap-4 rounded-md disabled:cursor-wait text-sm font-medium box-border',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus',
  ],
  {
    variants: {
      variant: {
        primary: 'hover:enabled:bg-primary-hover bg-primary text-white shadow-sm',
        outline:
          'border border-primary bg-background-50 text-primary hover:enabled:bg-background-100',
        icon: 'border border-transparent hover:border-primary',
      },
      size: {
        medium: 'h-9 py-2 px-4 min-w-32',
        fit: 'h-fit p-2',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  },
);
type ButtonVariants = VariantProps<typeof buttonVariants>;

@Component({
  selector: 'ods-button',
  standalone: true,
  imports: [CommonModule, SpinnerIconComponent],
  template: `<button
    type="button"
    [ngClass]="buttonVariants({ size, variant })"
    [disabled]="isLoading"
    [attr.aria-disabled]="isLoading"
    [attr.aria-label]="text"
    [attr.data-test-id]="dataTestId"
    (click)="clickEmitter.emit()"
  >
    <ng-content *ngIf="!isLoading" select="[icon]"></ng-content>
    <ods-spinner-icon *ngIf="isLoading" [size]="spinnerSize"></ods-spinner-icon>
    <div *ngIf="text" class="flex-grow">{{ text }}</div>
  </button>`,
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() dataTestId: string = '';
  @Input() isLoading: boolean = false;
  @Input() variant: ButtonVariants['variant'];
  @Input() size: ButtonVariants['size'];
  @Input() spinnerSize: IconVariants['size'] = 'medium';

  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  buttonVariants = buttonVariants;
}
