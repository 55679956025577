/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TechSharedModule } from '@alfa-client/tech-shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NavigationEffects } from './+state/navigation.effects';
import { NavigationFacade } from './+state/navigation.facade';
import * as fromNavigation from './+state/navigation.reducer';
import { NavigationService } from './navigation.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromNavigation.NAVIGATION_FEATURE_KEY, fromNavigation.reducer),
    EffectsModule.forFeature([NavigationEffects]),
    TechSharedModule,
  ],
  providers: [NavigationFacade, NavigationService],
})
export class NavigationSharedModule {}
