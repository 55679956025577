/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  BinaryFileListResource,
  BinaryFileResource,
  BinaryFileService,
  getBinaryFiles,
} from '@alfa-client/binary-file-shared';
import {
  CommandResource,
  CommandService,
  doIfCommandIsDone,
  hasCommandError,
  isDone,
  isPending,
} from '@alfa-client/command-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import {
  StateResource,
  createEmptyStateResource,
  createStateResource,
  doIfLoadingRequired,
  isNotNull,
  isNotUndefined,
} from '@alfa-client/tech-shared';
import { SnackBarService } from '@alfa-client/ui';
import { VorgangResource, VorgangService } from '@alfa-client/vorgang-shared';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { Resource, hasLink } from '@ngxp/rest';
import { isNil, isNull } from 'lodash-es';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { first, map, take, tap } from 'rxjs/operators';
import { PostfachFacade } from './+state/postfach.facade';
import { PostfachMailLinkRel, PostfachMailListLinkRel } from './postfach.linkrel';
import { PostfachMessages } from './postfach.message';
import {
  CreatePostfachMailCommand,
  PostfachFeatures,
  PostfachMail,
  PostfachMailListResource,
  PostfachMailResource,
  PostfachSettings,
} from './postfach.model';
import { PostfachRepository } from './postfach.repository';
import { createResendPostfachMailCommand, createSendPostfachMailCommand } from './postfach.util';

@Injectable({ providedIn: 'root' })
export class PostfachService {
  private readonly isPollSendPostachMail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  postfachMailList$: BehaviorSubject<StateResource<PostfachMailListResource>> = new BehaviorSubject<
    StateResource<PostfachMailListResource>
  >(createEmptyStateResource<PostfachMailListResource>());

  private navigationSubscription: Subscription;

  private sendPostfachMailSubscription: Subscription;
  private loadPostfachMailSubscription: Subscription;
  private vorgangSubscription: Subscription;
  private postfachNachrichtenListSubscription: Subscription;

  constructor(
    private repository: PostfachRepository,
    private commandService: CommandService,
    private navigationService: NavigationService,
    private vorgangService: VorgangService,
    private snackbarService: SnackBarService,
    private dialog: MatDialog,
    private binaryFileService: BinaryFileService,
    private postfachFacade: PostfachFacade,
  ) {
    this.listenToNavigation();
  }

  public sendMail(postfachMail: PostfachMail): Observable<StateResource<CommandResource>> {
    return this.doSendNachricht(
      this.postfachMailList$.value.resource,
      PostfachMailListLinkRel.SEND_POSTFACH_MAIL,
      createSendPostfachMailCommand(postfachMail),
    );
  }

  public sendExistingMail(
    postfachMailResource: PostfachMailResource,
    postfachMail: PostfachMail,
  ): Observable<StateResource<CommandResource>> {
    return this.doSendNachricht(
      postfachMailResource,
      PostfachMailLinkRel.SEND,
      createSendPostfachMailCommand(postfachMail),
    );
  }

  public resendMail(
    postfachMailResource: PostfachMailResource,
  ): Observable<StateResource<CommandResource>> {
    return this.doSendNachricht(
      postfachMailResource,
      PostfachMailLinkRel.RESEND_POSTFACH_MAIL,
      createResendPostfachMailCommand(),
    );
  }

  doSendNachricht(
    resource: Resource,
    linkRel: string,
    command: CreatePostfachMailCommand,
  ): Observable<StateResource<CommandResource>> {
    this.vorgangService.setPendingSendPostfachMailSingleCommandLoading();

    this.commandService
      .createCommand(resource, linkRel, command)
      .pipe(first())
      .subscribe((createdCommand) => this.handleSendNachrichtCommand(createdCommand));

    return this.getPendingSendPostfachMailCommand();
  }

  handleSendNachrichtCommand(commandStateResource: StateResource<CommandResource>): void {
    this.vorgangService.setPendingSendPostfachMailCommand(commandStateResource);
    this.commandIsDone(commandStateResource);
  }

  commandIsDone(commandStateResource: StateResource<CommandResource>): void {
    doIfCommandIsDone(commandStateResource.resource, () => {
      this.showSnackbar(commandStateResource.resource);
      this.handleSendPostfachMailIsDone(commandStateResource);
    });
  }

  private showSnackbar(commandResource: CommandResource): void {
    if (hasCommandError(commandResource)) {
      this.snackbarService.showError(PostfachMessages.SEND_FAILED);
    } else {
      this.snackbarService.show(commandResource, PostfachMessages.SEND_SUCCESSFUL);
    }
  }

  public getPendingSendPostfachMailCommand(): Observable<StateResource<CommandResource>> {
    return this.vorgangService
      .getPendingSendPostfachMailCommand()
      .pipe(map((pendingCommand) => this.pollSendPostfachMailCommand(pendingCommand)));
  }

  listenToNavigation(): void {
    this.unsubscribeToNavigation();
    this.navigationSubscription = this.navigationService
      .urlChanged()
      .subscribe((params) => this.onNavigation(params));
  }

  unsubscribeToNavigation(): void {
    if (!isNil(this.navigationSubscription)) this.navigationSubscription.unsubscribe();
  }

  onNavigation(params: Params): void {
    if (NavigationService.isVorgangListPage(params)) {
      this.setPollingFalse();
      this.clearPostfachMailList();
      this.closeOpenDialogs();
      this.unsubscribe();
    }
    if (NavigationService.isVorgangDetailPage(params, VorgangService.VORGANG_WITH_EINGANG_URL)) {
      this.setPostfachMailOnReload();
    }
    if (this.navigationService.isPostfachPage()) {
      this.resetHasNewPostfachNachrichten();
    }
  }

  setPollingFalse(): void {
    this.isPollSendPostachMail.next(false);
  }

  clearPostfachMailList(): void {
    this.postfachMailList$.next(createEmptyStateResource<PostfachMailListResource>());
  }

  closeOpenDialogs(): void {
    this.dialog.closeAll();
  }

  unsubscribe(): void {
    if (isNotUndefined(this.sendPostfachMailSubscription))
      this.sendPostfachMailSubscription.unsubscribe();
    if (isNotUndefined(this.loadPostfachMailSubscription))
      this.loadPostfachMailSubscription.unsubscribe();
    if (isNotUndefined(this.vorgangSubscription)) this.vorgangSubscription.unsubscribe();
  }

  setPostfachMailOnReload(): void {
    this.postfachMailList$.next({ ...this.postfachMailList$.value, reload: true });
  }

  resetHasNewPostfachNachrichten(): void {
    this.postfachNachrichtenListSubscription = this.getPostfachMailListByVorgang().subscribe(
      (postfachNachrichtenList) => {
        if (isNotNull(postfachNachrichtenList.resource)) {
          setTimeout(() => this.postfachNachrichtenListSubscription.unsubscribe(), 0);
          this.doResetHasNewPostfachNachrichten();
        }
      },
    );
  }

  doResetHasNewPostfachNachrichten(): void {
    if (
      hasLink(
        this.postfachMailList$.value.resource,
        PostfachMailListLinkRel.RESET_HAS_NEW_POSTFACH_NACHRICHT,
      )
    ) {
      this.repository
        .resetHasNewPostfachNachrichten(this.postfachMailList$.value.resource)
        .pipe(take(1))
        .subscribe();
    }
  }

  pollSendPostfachMailCommand(
    command: StateResource<CommandResource>,
  ): StateResource<CommandResource> {
    if (this.shouldPoll(command)) {
      this.setPollingTrue();
      this.sendPostfachMailSubscription = this.commandService
        .pollCommand(command.resource)
        .subscribe((updatedStateResource) => {
          this.vorgangService.setPendingSendPostfachMailCommand(updatedStateResource);

          if (isDone(updatedStateResource.resource)) {
            this.handleSendPostfachMailIsDone(updatedStateResource);
            setTimeout(() => this.sendPostfachMailSubscription.unsubscribe(), 0);
          }
        });
    }
    return command;
  }

  private shouldPoll(command: StateResource<CommandResource>): boolean {
    return command.loaded && isPending(command.resource) && !this.isPollSendPostachMail.value;
  }

  setPollingTrue(): void {
    this.isPollSendPostachMail.next(true);
  }

  handleSendPostfachMailIsDone(updatedStateResource: StateResource<CommandResource>): void {
    this.refreshPostfachMailList(updatedStateResource);
    this.setPollingFalse();
  }

  refreshPostfachMailList(updatedStateResource: StateResource<CommandResource>): void {
    this.setPostfachMailListLoading();
    this.getEffectedResource(updatedStateResource)
      .pipe(first())
      .subscribe((effectedResource) => {
        this.setPostfachMailList(effectedResource);
      });
  }

  getEffectedResource(
    updatedStateResource: StateResource<CommandResource>,
  ): Observable<PostfachMailListResource> {
    return this.commandService.getEffectedResource<PostfachMailListResource>(
      updatedStateResource.resource,
    );
  }

  public getPostfachMailListByGivenVorgang(
    vorgang: VorgangResource,
  ): Observable<StateResource<PostfachMailListResource>> {
    doIfLoadingRequired(this.postfachMailList$.value, () => {
      this.setPostfachMailListLoading();
      this.loadPostfachMailsByVorgang(vorgang);
    });
    return this.postfachMailList$.asObservable();
  }

  public getPostfachMailListByVorgang(): Observable<StateResource<PostfachMailListResource>> {
    doIfLoadingRequired(this.postfachMailList$.value, () => {
      this.setPostfachMailListLoading();
      this.vorgangSubscription = this.vorgangService
        .getVorgangWithEingang()
        .subscribe((vorgangWithEingangStateResource) => {
          if (vorgangWithEingangStateResource.resource) {
            this.loadPostfachMailsByVorgang(vorgangWithEingangStateResource.resource);
            setTimeout(() => this.vorgangSubscription.unsubscribe(), 0);
          }
        });
    });
    return this.postfachMailList$.asObservable();
  }

  setPostfachMailListLoading(): void {
    this.postfachMailList$.next({ ...this.postfachMailList$.value, loading: true });
  }

  public loadPostfachMailsByVorgang(vorgang: VorgangResource): void {
    this.loadPostfachMailSubscription = this.repository
      .loadPostfachMailList(vorgang)
      .subscribe((postfachMaiList) => {
        if (!isNull(postfachMaiList)) {
          this.setPostfachMailList(postfachMaiList);
          setTimeout(() => this.loadPostfachMailSubscription.unsubscribe(), 0);
        }
      });
  }

  setPostfachMailList(postfachMailList: PostfachMailListResource): void {
    this.postfachMailList$.next(createStateResource(postfachMailList));
  }

  public loadAttachments(
    postfachNachricht: PostfachMailResource,
  ): Observable<StateResource<BinaryFileListResource>> {
    return this.binaryFileService.getFiles(postfachNachricht, PostfachMailLinkRel.ATTACHMENTS);
  }

  public isDownloadPdfInProgress(): Observable<boolean> {
    return combineLatest([
      this.vorgangService.getVorgangWithEingang(),
      this.postfachFacade.isDownloadPdfInProgress(),
    ]).pipe(
      tap(([vorgang, isDownloadInProgress]) => {
        if (isDownloadInProgress && vorgang.resource) {
          this.postfachFacade.downloadPdf(vorgang.resource);
        }
      }),
      map(([, isDownloadInProgress]) => isDownloadInProgress),
    );
  }

  public downloadPdf(): void {
    this.postfachFacade.startDownloadPdf();
  }

  public getAttachments(postfachNachricht: PostfachMailResource): Observable<BinaryFileResource[]> {
    return this.postfachFacade.getAttachmentList().pipe(
      tap((attachmentList) =>
        doIfLoadingRequired(attachmentList, () =>
          this.postfachFacade.loadAttachmentList(postfachNachricht),
        ),
      ),
      map(getBinaryFiles),
    );
  }

  public clearAttachmentList(): void {
    this.postfachFacade.clearAttachmentList();
  }

  public getFeatures(): Observable<PostfachFeatures> {
    return this.getPostfachMailListByVorgang().pipe(
      map(
        (listStateResource: StateResource<PostfachMailListResource>) =>
          listStateResource.resource.features,
      ),
    );
  }

  public getSettings(): Observable<PostfachSettings> {
    return this.getPostfachMailListByVorgang().pipe(
      map(
        (listStateResource: StateResource<PostfachMailListResource>) =>
          listStateResource.resource.settings,
      ),
    );
  }
}
