<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<h1 mat-dialog-title data-test-id="dialog-header">
  {{ data.title }}
</h1>
<div mat-dialog-content>
  <p data-test-id="dialog-message">{{ data.message }}</p>
</div>
<div mat-dialog-actions>
  <ozgcloud-stroked-button-with-spinner
    dataTestId="dialog-confirmation-button"
    [mat-dialog-close]="true"
    svgIcon="{{ data.okSvgIcon }}"
    text="{{ data.okText }}"
  >
  </ozgcloud-stroked-button-with-spinner>

  <ozgcloud-stroked-button-with-spinner
    dataTestId="dialog-cancel-button"
    (click)="onNoClick()"
    text="Abbrechen"
  >
  </ozgcloud-stroked-button-with-spinner>
</div>
