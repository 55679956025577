/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiRootFacade, ApiRootResource } from '@alfa-client/api-root-shared';
import { ENVIRONMENT_CONFIG } from '@alfa-client/environment-shared';
import { NavigationService } from '@alfa-client/navigation-shared';
import { StateResource, isNotNull } from '@alfa-client/tech-shared';
import { IconService } from '@alfa-client/ui';
import { buildPathSegmentsFromLocalStorage } from '@alfa-client/vorgang-shared';
import { Component, Inject, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Environment } from 'libs/environment-shared/src/lib/environment.model';
import { Observable, Subscription, filter, tap } from 'rxjs';

@Component({
  selector: 'alfa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public apiRoot$: Observable<StateResource<ApiRootResource>>;
  private oAuthEventSubscription: Subscription;

  constructor(
    @Inject(ENVIRONMENT_CONFIG) private envConfig: Environment,
    private apiRootFacade: ApiRootFacade,
    private iconService: IconService,
    private oAuthService: OAuthService,
    private navigationService: NavigationService,
  ) {
    this.iconService.registerIcons();
  }

  ngOnInit(): void {
    this.proceedWithLogin();
    this.listenToOAuthEvents();
  }

  proceedWithLogin(): void {
    this.oAuthService.configure(this.buildConfiguration());
    this.oAuthService.setupAutomaticSilentRefresh();
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.oAuthService.loadDiscoveryDocumentAndLogin();
  }

  buildConfiguration(): AuthConfig {
    return {
      // Url of the Identity Provider
      issuer: this.envConfig.authServer + '/realms/' + this.envConfig.realm,

      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin + this.buildInitialPath() + window.location.search,
      postLogoutRedirectUri: window.location.origin + '/',

      // URL of the SPA to redirect the user after silent refresh
      silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
      useSilentRefresh: true,

      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: this.envConfig.clientId,

      // set the scope for the permissions the client should request
      scope: 'openid profile email',
      requireHttps: false,
    };
  }

  buildInitialPath(): string {
    const currentPath: string = window.location.pathname;
    if (currentPath === '/') {
      return currentPath + buildPathSegmentsFromLocalStorage().join('/');
    }

    return currentPath;
  }

  buildInitialQueryParams(): Params | undefined {
    const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
    if (!queryParams.toString()) {
      return undefined;
    }

    const params: Params = {};
    queryParams.forEach((value: string, key: string) => {
      params[key] = value;
    });

    return params;
  }

  listenToOAuthEvents(): void {
    this.oAuthEventSubscription = this.oAuthService.events
      .pipe(
        filter((event: OAuthEvent) => this.consideredAsLoginEvent(event.type) || this.consideredAsPageReloadEvent(event.type)),
      )
      .subscribe(() => (this.apiRoot$ = this.loadApiRootAndNavigate()));
  }

  consideredAsLoginEvent(eventType: string): boolean {
    return eventType === 'token_received';
  }

  consideredAsPageReloadEvent(eventType: string): boolean {
    return eventType === 'discovery_document_loaded' && this.hasValidToken();
  }

  hasValidToken(): boolean {
    return this.oAuthService.hasValidAccessToken() && this.oAuthService.hasValidIdToken();
  }

  loadApiRootAndNavigate(): Observable<StateResource<ApiRootResource>> {
    return this.apiRootFacade.getApiRoot().pipe(
      filter((apiRoot) => isNotNull(apiRoot.resource)),
      tap(() => {
        this.navigationService.navigate(this.buildInitialPath(), this.buildInitialQueryParams());
        this.oAuthEventSubscription.unsubscribe();
      }),
    );
  }
}
