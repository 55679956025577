/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { isNotUndefined } from '@alfa-client/tech-shared';
import { RouteData } from './+state/navigation.models';

export function buildRouteData(action: any): RouteData {
  const root = action.payload.routerState.root;
  const lastFirst = getLastFirstChild(root);
  return { queryParameter: lastFirst.params, urlSegments: lastFirst.url };
}

function getLastFirstChild(route: any) {
  while (isNotUndefined(route.firstChild)) route = route.firstChild;
  return route;
}

export function revertDoubleEncodingOfPercentageSign(text: string): string {
  return text.replace(/%25/g, '%');
}
