/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { ValidationMessageCode } from './validation/tech.validation.messages';

/*
 * Copyright (C) 2022 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export interface ApiError {
  issues: Issue[];
}

export interface Issue {
  field: string;
  messageCode: string; //TODO statt string -> ValidationMessageCode nutzen
  message: string;
  parameters: IssueParam[];
  exceptionId?: string;
}

export interface IssueParam {
  name: string;
  value: string;
}

export interface ProblemDetail {
  type: string;
  title: string;
  status: HttpStatusCode;
  detail: string;
  instance: string;
  invalidParams: InvalidParam[];
}

export interface InvalidParam {
  constraintParameters: ConstraintParameter[];
  name: string;
  reason: ValidationMessageCode;
  value: string;
}

export interface ConstraintParameter {
  name: string;
  value: string;
}

export declare type HttpError = ProblemDetail | ApiError;

export enum HttpMethod {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  GET = 'GET',
}

export enum HttpHeader {
  ACCEPT = 'Accept',
  CONTENT_DISPOSITION = 'content-disposition',
  LOCATION = 'Location',
}

export interface BlobWithFileName {
  blob: Blob;
  fileName: string;
}

export enum ContentType {
  APPLICATION_JSON = 'application/json',
  APPLICATION_PDF = 'application/pdf',
  IMAGES_ALL = 'images/*',
  APPLICATION_ALL = 'application/*',
  APPLICATION_OCTET_STREAM = 'application/octet-stream',
  APPLICATION_ZIP = 'application/zip',
}

export interface GetRequestOptions {
  headers: HttpHeaders | { [header: string]: string | string[] };
  responseType: 'json';
}

export type StringBasedKeyMap<T> = { [key: string]: T };
