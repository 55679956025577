/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommandResource, isSuccessfulDone } from '@alfa-client/command-shared';
import {
  StateResource,
  createEmptyStateResource,
  isEmptyStateResource,
} from '@alfa-client/tech-shared';

export class OzgcloudDialogCommandResult<D = unknown> {
  stateResource: StateResource<CommandResource>;
  data?: D;
}

export function createDialogCancelResult(): OzgcloudDialogCommandResult {
  return { stateResource: createEmptyStateResource() };
}

export function createDialogResult(
  stateResource: StateResource<CommandResource>,
): OzgcloudDialogCommandResult {
  return { stateResource };
}

export function isDialogCanceled(dialogResult?: OzgcloudDialogCommandResult): boolean {
  return dialogResult && isEmptyStateResource(dialogResult.stateResource);
}

export function isDialogSuccessfullyCompleted(dialogResult?: OzgcloudDialogCommandResult): boolean {
  return dialogResult && isSuccessfulDone(dialogResult.stateResource?.resource);
}
