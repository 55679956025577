/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Resource } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { ResourceServiceConfig } from './resource.model';
import { ResourceRepository } from './resource.repository';
import { ResourceService } from './resource.service';

export class ApiResourceService<B extends Resource, T extends Resource> extends ResourceService<B, T> {
  constructor(
    protected config: ResourceServiceConfig<B>,
    protected repository: ResourceRepository,
  ) {
    super(config, repository);
  }

  doSave(resource: T, toSave: unknown): Observable<T> {
    return <Observable<T>>this.repository.save({
      resource,
      linkRel: this.config.edit.linkRel,
      toSave,
    });
  }

  doPatch(resource: T, toPatch: unknown): Observable<T> {
    return <Observable<T>>this.repository.patch({
      resource,
      linkRel: this.config.edit.linkRel,
      toSave: toPatch,
    });
  }
}
