/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { addRequestHeader, existRequestHeader, isChangingDataRequest } from '../http.util';
import { isNotNull } from '../tech.util';

@Injectable()
export class HttpXsrfInterceptor implements HttpInterceptor {
  static readonly X_XSRF_TOKEN_HEADER: string = 'X-XSRF-TOKEN';

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isChangingDataRequest(request)) {
      request = this.handleRequest(request);
    }
    return next.handle(request);
  }

  private handleRequest(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let token: string = this.getToken();
    if (isNotNull(token) && !existRequestHeader(request, HttpXsrfInterceptor.X_XSRF_TOKEN_HEADER)) {
      request = addRequestHeader(request, HttpXsrfInterceptor.X_XSRF_TOKEN_HEADER, token);
    }
    return request;
  }

  private getToken(): string {
    return this.tokenExtractor.getToken();
  }
}
