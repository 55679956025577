/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_CONFIG, Environment } from '@alfa-client/environment-shared';
import { OAuthService } from 'angular-oauth2-oidc';
import { removeIdTokenFromSessionStorage } from './storage/storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private oAuthService: OAuthService,
    @Inject(ENVIRONMENT_CONFIG) private envConfig: Environment,
  ) {}

  public hasNoValidToken(): boolean {
    return !(this.oAuthService.hasValidAccessToken() && this.oAuthService.hasValidIdToken());
  }

  public logout(): void {
    this.oAuthService.logOut();
  }

  public logoutWithConfirmation(): void {
    removeIdTokenFromSessionStorage();

    const customParameters: object = { client_id: this.envConfig.clientId };
    this.oAuthService.logOut(customParameters);
  }
}
