/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EMPTY_STRING } from '../../../../../tech-shared/src';
import { TextInputComponent } from '../../form/text-input/text-input.component';
import { CloseIconComponent } from '../../icons/close-icon/close-icon.component';
import { SearchIconComponent } from '../../icons/search-icon/search-icon.component';

@Component({
  selector: 'ods-search-field',
  standalone: true,
  imports: [CommonModule, TextInputComponent, SearchIconComponent, CloseIconComponent],
  template: `<ods-text-input
    label="instant search"
    [fieldControl]="control"
    [placeholder]="placeholder"
    [withPrefix]="true"
    [withSuffix]="true"
    [showLabel]="false"
    (clickEmitter)="inputClicked.emit()"
    role="combobox"
  >
    <ods-search-icon prefix aria-hidden="true" aria-label="Suchfeld" />
    <button
      suffix
      *ngIf="control.value"
      (click)="clearInput()"
      aria-label="Eingabe löschen"
      data-test-id="clear-instant-search"
    >
      <ods-close-icon class="fill-primary hover:fill-primary-hover" />
    </button>
  </ods-text-input>`,
})
export class SearchFieldComponent {
  @Input() placeholder: string = EMPTY_STRING;
  @Input() control: FormControl<string> = new FormControl(EMPTY_STRING);

  @Output() inputClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() searchQueryCleared: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  clearInput(): void {
    this.control.setValue(EMPTY_STRING);
    this.searchQueryCleared.emit();
  }
}
