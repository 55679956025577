/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export enum Icons {
  ACCOUNT_OUTLINE = 'account_outline',
  ASSIGNMENT_IND = 'assignment_ind',
  AZ = 'az',
  DELETE_FINALLY = 'delete_finally',
  DISCARD_DOCUMENT = 'discard_document',
  DONE = 'done',
  EDIT = 'edit',
  INCOMING = 'incoming',
  LOESCHEN_ANFORDERN = 'request_deletion',
  LOESCH_ANFORDERUNG_ZURUECKNEHMEN = 'undo_request_deletion',
  LOGO = 'logo',
  MAIL = 'mail',
  NO_REPLY = 'no_reply',
  NR = 'nr',
  PDF_FILE = 'pdf_file',
  PDF_EXPORT = 'pdf_export',
  RESUBMISSION_EXPIRED = 'resubmission_expired',
  STAMP = 'stamp',
  VORGANG_VORPRUEFEN = 'vorgang_vorpruefen',
  XDOMEA = 'xdomea_file',
  PASTE_FROM_CLIPBOARD = 'paste_from_clipboard',
  UPDATE = 'update',
}
