/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ApiError, MessageCode, StateResource, createEmptyStateResource, hasStateResourceError } from '@alfa-client/tech-shared';
import { UserProfileResource, getUserName, getUserNameInitials, userProfileMessage } from '@alfa-client/user-profile-shared';
import { Component, Input, SimpleChanges } from '@angular/core';
import { isUndefined } from 'lodash-es';

@Component({
  selector: 'alfa-user-icon',
  templateUrl: './user-icon.component.html',
})
export class UserIconComponent {
  @Input() userProfileStateResource: StateResource<UserProfileResource> = createEmptyStateResource<UserProfileResource>();
  @Input() disableTooltip: boolean = false;

  readonly messageCode = MessageCode;

  tooltip: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userProfileStateResource) {
      this.tooltip = this.getTooltip();
    }
  }

  getTooltip(): string {
    if (this.userProfileStateResource.resource) {
      return this.getUserTooltip();
    }
    if (hasStateResourceError(this.userProfileStateResource)) {
      return this.getErrorTooltip();
    }
    return userProfileMessage.UNASSIGNED;
  }

  getUserTooltip(): string {
    return getUserName(this.userProfileStateResource.resource);
  }

  getErrorTooltip(): string {
    const message: string = userProfileMessage[this.errorMessageCode];
    return isUndefined(message) ? userProfileMessage.UNKNOW_ERROR : message;
  }

  get errorMessageCode(): string {
    return (<ApiError>this.userProfileStateResource.error)?.issues[0]?.messageCode;
  }

  get initials(): string {
    return getUserNameInitials(this.userProfileStateResource.resource);
  }
}
