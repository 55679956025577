/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input } from '@angular/core';
import { UserProfileService } from '@alfa-client/user-profile-shared';
import { VorgangHeaderLinkRel, VorgangWithEingangResource } from '@alfa-client/vorgang-shared';

@Component({
  selector: 'alfa-assign-user-profile-button-container',
  templateUrl: './assign-user-profile-button-container.component.html',
  styleUrls: ['./assign-user-profile-button-container.component.scss'],
})
export class AssignUserProfileButtonContainerComponent {
  @Input() vorgang: VorgangWithEingangResource;

  readonly linkRel = VorgangHeaderLinkRel;

  constructor(private userProfileService: UserProfileService) {}

  public showUserProfileSearch(): void {
    document.documentElement.scrollTop = 0;
    this.userProfileService.showUserProfileSearch();
  }
}
