/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';

import * as UserProfileActions from './user-profile.actions';

export const USER_PROFILE_FEATURE_KEY = 'UserProfileState';

export interface UserProfilePartialState {
  readonly [USER_PROFILE_FEATURE_KEY]: UserProfileState;
}

export interface UserProfileState {
  userProfileSearchVisibilty: boolean;
}

export const initialState: UserProfileState = {
  userProfileSearchVisibilty: false,
};

const userProfileReducer: ActionReducer<UserProfileState, Action> = createReducer(
  initialState,
  on(
    UserProfileActions.showUserProfileSearch,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfileSearchVisibilty: true,
    }),
  ),
  on(
    UserProfileActions.hideUserProfileSearch,
    (state: UserProfileState): UserProfileState => ({
      ...state,
      userProfileSearchVisibilty: false,
    }),
  ),
);

export function reducer(state: UserProfileState, action: Action): UserProfileState {
  return userProfileReducer(state, action);
}
