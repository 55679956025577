/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@alfa-client/app-shared';
import { ApiError, HttpErrorHandler, isForbidden, isServerError, isUnauthorized } from '@alfa-client/tech-shared';
import { DialogService, Messages, SnackBarService } from 'libs/ui/src';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackBarService,
    private errorHandler: HttpErrorHandler,
    private authService: AuthService,
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const defaultHandling: boolean = this.errorHandler.shouldDoDefaultHandling();
    return next.handle(request).pipe(
      catchError((error: any) => this.handleErrorResponse(error, defaultHandling)),
      finalize(() => this.errorHandler.enableDefaultHandling()),
    );
  }

  handleErrorResponse(error: any, defaultHandling: boolean): Observable<any> {
    if (defaultHandling && this.hasResponse(error)) {
      return this.handleError(error);
    }

    return throwError({ error: error.response });
  }

  handleError(error: any): Observable<any> {
    const errorResponse: HttpErrorResponse = error.response;

    if (isServerError(errorResponse.status)) {
      this.handleServerError(errorResponse.error);
      return EMPTY;
    }
    if (isUnauthorized(errorResponse.status)) {
      this.handleUnauthorizedError();
      return EMPTY;
    }
    if (isForbidden(errorResponse.status)) {
      this.handleForbiddenError();
      // return EMPTY;
    }
    return throwError({ error: errorResponse });
  }

  private handleServerError(error: ApiError): void {
    this.dialogService.openApiErrorInfo(error);
  }

  handleUnauthorizedError(): void {
    this.authService.logoutWithConfirmation();
  }

  handleForbiddenError(): void {
    if (!this.snackbarService.isVisible()) {
      this.snackbarService.showError(Messages.HTTP_STATUS_FORBIDDEN);
    }
  }

  private hasResponse(error: any): boolean {
    return error.hasOwnProperty('response');
  }
}
