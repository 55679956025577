import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AccessibilityIconComponent, LinkComponent, TooltipDirective } from '@ods/system';

@Component({
  selector: 'common-accessibility-button',
  standalone: true,
  imports: [CommonModule, AccessibilityIconComponent, LinkComponent, TooltipDirective],
  template: `<ods-link [url]="url" [linkTestId]="linkTestId" tooltip="Barrierefreiheit" [openInNewTab]="true">
    <ods-accessibility-icon size="large" />
  </ods-link>`,
})
export class AccessibilityButtonComponent {
  @Input({ required: true }) url!: string;
  @Input() linkTestId: string | null = null;
}
