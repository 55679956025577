/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormControlEditorAbstractComponent } from 'libs/design-component/src/lib/form/formcontrol-editor.abstract.component';
import { CheckboxEnumEditorItem } from './checkbox-enum-editor.model';

/**
 * Ein Checkbox, dessen Wert beim FormControl statt boolean als string aus dem enum editor item
 * abgebildet wird.
 */
@Component({
  selector: 'ozgcloud-checkbox-enum-editor',
  templateUrl: './checkbox-enum-editor.component.html',
  styleUrls: ['./checkbox-enum-editor.component.scss'],
})
export class CheckboxEnumEditorComponent
  extends FormControlEditorAbstractComponent
  implements OnInit
{
  @Input() label: string;
  @Input() defaultItem: CheckboxEnumEditorItem;
  @Input() checkedItem: CheckboxEnumEditorItem;
  @Input() uncheckedItem: CheckboxEnumEditorItem;

  public selected: boolean;

  override ngOnInit(): void {
    this.selected = this.defaultItem === this.checkedItem;
    this.writeValue(this.defaultItem.type);
  }

  setSelection(event: MatCheckboxChange): void {
    this.writeValue(event.checked ? this.checkedItem.type : this.uncheckedItem.type);
  }
}
