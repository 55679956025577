/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ods-dropdown-menu-button',
  standalone: true,
  imports: [CommonModule],
  template: `<button
    class="flex min-h-12 w-full items-center gap-4 border-2 border-transparent px-4 py-3
    text-start outline-none hover:border-primary focus-visible:border-focus"
    role="menuitem"
    (click)="itemClicked.emit()"
  >
    <ng-content select="[icon]" />
    <p class="text-text">{{ caption }}</p>
  </button>`,
})
export class DropdownMenuButtonComponent {
  @Input({ required: true }) caption!: string;

  @Output() itemClicked: EventEmitter<MouseEvent> = new EventEmitter();
}
