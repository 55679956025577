/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { SpinnerIconComponent } from '../icons/spinner-icon/spinner-icon.component';

@Component({
  selector: 'ods-button-card',
  standalone: true,
  imports: [CommonModule, SpinnerIconComponent],
  styles: [':host {@apply inline-flex}'],
  template: `<button
    type="button"
    class="flex flex-grow items-center justify-center gap-4 rounded-md bg-background-50 py-3 pl-6 pr-6 text-text hover:bg-background-100 focus:outline-none  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ozgblue-800 disabled:cursor-wait disabled:hover:bg-background-50"
    [disabled]="isLoading"
    [attr.aria-disabled]="isLoading"
    [attr.aria-label]="getAriaLabel()"
  >
    <ng-content *ngIf="!isLoading" select="[icon]"></ng-content>
    <ods-spinner-icon *ngIf="isLoading" size="extra-large" class="shrink-0" />
    <div class="flex-grow break-all">
      {{ text }}
      <br *ngIf="subText" aria-hidden="true" />
      {{ subText }}
    </div>
  </button>`,
})
export class ButtonCardComponent {
  @Input({ required: true }) text!: string;
  @Input() subText: string = '';
  @Input() isLoading: boolean = false;

  getAriaLabel() {
    return this.text + (this.subText ? ` ${this.subText}` : '');
  }
}
