/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  ApiErrorAction,
  StateResource,
  createEmptyStateResource,
  createErrorStateResource,
  createStateResource,
} from '@alfa-client/tech-shared';
import { Action, createReducer, on } from '@ngrx/store';
import { HintListResource } from '../hint.model';
import { HintListProps } from './hint.actions';

import * as HintActions from './hint.actions';

export const HINT_FEATURE_KEY = 'HintState';

export interface HintState {
  hintList: StateResource<HintListResource>;
}

export interface HintPartialState {
  readonly [HINT_FEATURE_KEY]: HintState;
}

export const initialState: HintState = {
  hintList: createEmptyStateResource<HintListResource>(),
};

const hintReducer = createReducer(
  initialState,
  on(HintActions.loadHintListSuccess, (state: HintState, props: HintListProps) => ({
    ...state,
    hintList: createStateResource(props.hintList),
  })),
  on(HintActions.loadHintListFailed, (state: HintState, props: ApiErrorAction) => ({
    ...state,
    hintList: createErrorStateResource(props.apiError),
  })),
);

export function reducer(state: HintState, action: Action) {
  return hintReducer(state, action);
}
