/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { getEmbeddedResource, getUrl, hasLink, ResourceUri } from '@ngxp/rest';
import { isEmpty, isNil, isObject } from 'lodash-es';
import { CommandLinkRel, CommandListLinkRel } from './command.linkrel';
import { CommandErrorMessage } from './command.message';
import { CommandListResource, CommandResource } from './command.model';

export function isRevokeable(commandResource: CommandResource): boolean {
  return hasLink(commandResource, CommandLinkRel.REVOKE);
}

export function isPending(commandResource: CommandResource): boolean {
  return hasLink(commandResource, CommandLinkRel.UPDATE);
}

export function isDone(commandResource: CommandResource): boolean {
  return hasLink(commandResource, CommandLinkRel.EFFECTED_RESOURCE);
}

export function hasErrorMessage(commandResource: CommandResource): boolean {
  return !isNil(commandResource.errorMessage) && !isEmpty(commandResource.errorMessage);
}

export function hasCommandError(commandResource: CommandResource): boolean {
  return hasErrorMessage(commandResource) && !isPending(commandResource);
}

export function notHasCommandError(commandResource: CommandResource): boolean {
  return !hasCommandError(commandResource);
}

export function getPendingCommandByOrder(
  pendingCommands: CommandListResource,
  commandOrder: any[],
): CommandResource {
  const commands: CommandResource[] = getEmbeddedCommandResources(pendingCommands).filter(
    (command) => commandOrder.includes(command.order),
  );
  return commands.length > 0 ? commands[0] : null;
}

function getEmbeddedCommandResources(commandListResource: CommandListResource): CommandResource[] {
  return getEmbeddedResource<CommandResource[]>(
    commandListResource,
    CommandListLinkRel.COMMAND_LIST,
  );
}

export function doIfCommandIsDone(commandResource: CommandResource, action: () => void) {
  if (isObject(commandResource) && isDone(commandResource)) action();
}

export function isConcurrentModification(errorMessage: string): boolean {
  return errorMessage === CommandErrorMessage.CONCURRENT_MODIFICATION;
}

export function isSuccessfulDone(commandResource: CommandResource): boolean {
  return isDone(commandResource) && !hasCommandError(commandResource);
}

export function getEffectedResourceUrl(command: CommandResource): ResourceUri {
  return getUrl(command, CommandLinkRel.EFFECTED_RESOURCE);
}
