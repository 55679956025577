/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BinaryFileListResource } from '../binary-file.model';
import { BinaryFileRepository } from '../binary-file.repository';
import {
  DownloadBinaryFileAsPdfAction,
  LoadBinaryFileListProps,
  SaveFileProps,
} from './binary-file.actions';

import { saveAs } from 'file-saver';
import * as BinaryFileActions from './binary-file.actions';

@Injectable()
export class BinaryFileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly repository: BinaryFileRepository,
  ) {}

  downloadPdf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BinaryFileActions.downloadPdf),
      switchMap((action: DownloadBinaryFileAsPdfAction) =>
        this.repository.downloadPdf(action.uri).pipe(
          mergeMap((fileData: Blob) => [
            BinaryFileActions.saveFile({ fileData, fileName: action.fileName }),
            action.successAction(),
          ]),
          catchError((error) => of(action.failureAction(error.error))),
        ),
      ),
    ),
  );

  saveFile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BinaryFileActions.saveFile),
        tap((props: SaveFileProps) => this.save(props.fileData, props.fileName)),
      ),
    { dispatch: false },
  );

  save(fileData: Blob, name: string): void {
    saveAs(fileData, name);
  }

  loadBinaryFileList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BinaryFileActions.loadBinaryFileList),
      mergeMap((props: LoadBinaryFileListProps) =>
        this.repository.getFiles(props.resource, props.linkRel).pipe(
          map((binaryFileList: BinaryFileListResource) => props.successAction(binaryFileList)),
          catchError((error) => of(props.failureAction(error.error))),
        ),
      ),
    ),
  );
}
