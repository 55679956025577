<!--

    Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<svg width="44" height="44" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g [ngClass]="[twMerge('fill-primary', class)]">
    <path
      d="M8.81083 9.89993H8.52662V9.04961C8.52662 8.89316 8.39959 8.76618 8.24241 8.76618C8.08523 8.76618 7.9582 8.89316 7.9582 9.04961V10.1834C7.9582 10.3398 8.08523 10.4668 8.24241 10.4668H8.81086C8.96804 10.4668 9.09507 10.3398 9.09507 10.1834C9.09507 10.0269 8.96801 9.89993 8.81083 9.89993Z"
    />
    <path
      d="M3.12693 17.2713H1.98944C1.83218 17.2713 1.70508 17.3983 1.70508 17.5547V19.2554C1.70508 19.4119 1.83218 19.5388 1.98944 19.5388H3.1269C3.28415 19.5388 3.41125 19.4119 3.41125 19.2554V17.5547C3.41129 17.3983 3.28419 17.2713 3.12693 17.2713ZM2.84254 18.972H2.27383V17.8382H2.84257L2.84254 18.972Z"
    />
    <path
      d="M3.41129 14.1538C3.41129 13.9973 3.28419 13.8703 3.12693 13.8703H1.98944C1.83218 13.8703 1.70508 13.9973 1.70508 14.1538V15.8545C1.70508 16.0109 1.83218 16.1379 1.98944 16.1379H3.1269C3.28415 16.1379 3.41125 16.0109 3.41125 15.8545L3.41129 14.1538ZM2.84254 15.571H2.27383V14.4372H2.84257L2.84254 15.571Z"
    />
    <path
      d="M3.98112 12.4533C3.98112 12.2969 3.85402 12.1699 3.69676 12.1699H0.568712V11.8865C0.568712 11.73 0.441614 11.603 0.284356 11.603C0.127099 11.603 0 11.73 0 11.8865V21.5237C0 21.9926 0.382763 22.3741 0.853102 22.3741H3.12805C3.59839 22.3741 3.98115 21.9926 3.98115 21.5237C3.98115 21.0549 3.59839 20.6734 3.12805 20.6734C3.07887 20.6734 3.02995 20.6776 2.98189 20.6861C2.78598 20.3338 2.41201 20.1065 1.99059 20.1065C1.56917 20.1065 1.19521 20.3338 0.999296 20.6861C0.951242 20.6776 0.902322 20.6734 0.853135 20.6734C0.752763 20.6734 0.658055 20.6938 0.568779 20.7255V12.7368H3.69683C3.85399 12.7368 3.98112 12.6098 3.98112 12.4533ZM0.853069 21.2403C0.907387 21.2403 0.959406 21.2559 1.00719 21.2865C1.08484 21.3367 1.18238 21.3457 1.26826 21.3109C1.35413 21.2763 1.41725 21.202 1.43861 21.1122C1.49833 20.854 1.72527 20.6734 1.99056 20.6734C2.25586 20.6734 2.48253 20.854 2.54251 21.1122C2.56384 21.202 2.62695 21.2763 2.71286 21.3109C2.79874 21.3458 2.89598 21.3367 2.97393 21.2865C3.15934 21.1675 3.41241 21.3131 3.41241 21.5237C3.41241 21.6799 3.28501 21.8072 3.12805 21.8072H0.853069C0.696111 21.8072 0.568712 21.6799 0.568712 21.5237C0.568712 21.3676 0.696111 21.2403 0.853069 21.2403Z"
    />
    <path
      d="M12.2294 21.2381H4.83585C4.6786 21.2381 4.5515 21.3652 4.5515 21.5217V22.0889C4.5515 22.2454 4.6786 22.3725 4.83585 22.3725C4.99311 22.3725 5.12021 22.2454 5.12021 22.0889V21.8053H11.945V22.0889C11.945 22.2454 12.0721 22.3725 12.2294 22.3725C12.3866 22.3725 12.5137 22.2454 12.5137 22.0889V21.5217C12.5138 21.3652 12.3866 21.2381 12.2294 21.2381Z"
    />
    <path
      d="M4.83585 20.6696C4.99311 20.6696 5.12021 20.5427 5.12021 20.3862V9.33167C5.12021 9.17522 4.99311 9.04823 4.83585 9.04823C4.6786 9.04823 4.5515 9.17522 4.5515 9.33167V20.3862C4.55146 20.5427 4.6786 20.6696 4.83585 20.6696Z"
    />
    <path
      d="M12.2303 8.76618C12.073 8.76618 11.9459 8.89317 11.9459 9.04962V20.3876C11.9459 20.544 12.073 20.671 12.2303 20.671C12.3876 20.671 12.5147 20.544 12.5147 20.3876V9.04962C12.5147 8.89317 12.3876 8.76618 12.2303 8.76618Z"
    />
    <path
      d="M16.7668 11.603C16.6095 11.603 16.4824 11.73 16.4824 11.8865V12.1699H13.3543C13.1971 12.1699 13.07 12.2969 13.07 12.4533C13.07 12.6098 13.1971 12.7368 13.3543 12.7368H16.4824V20.7255C16.3928 20.6938 16.2981 20.6734 16.198 20.6734C16.1489 20.6734 16.0999 20.6776 16.0519 20.6861C15.856 20.3338 15.482 20.1065 15.0606 20.1065C14.6392 20.1065 14.2652 20.3338 14.0693 20.6861C14.0212 20.6776 13.9723 20.6734 13.9231 20.6734C13.4528 20.6734 13.07 21.0549 13.07 21.5237C13.07 21.9925 13.4528 22.3741 13.9231 22.3741H16.1981C16.6684 22.3741 17.0512 21.9925 17.0512 21.5237V11.8865C17.0511 11.73 16.924 11.603 16.7668 11.603ZM16.198 21.8072H13.9231C13.7661 21.8072 13.6387 21.68 13.6387 21.5238C13.6387 21.3676 13.7661 21.2403 13.9231 21.2403C13.9774 21.2403 14.0294 21.2559 14.0772 21.2865C14.1551 21.3367 14.2524 21.3458 14.3382 21.3109C14.4241 21.2763 14.4872 21.2021 14.5086 21.1122C14.5683 20.854 14.7952 20.6734 15.0605 20.6734C15.3258 20.6734 15.5525 20.854 15.6125 21.1122C15.6338 21.2021 15.6969 21.2763 15.7828 21.3109C15.8687 21.3458 15.9659 21.3367 16.0438 21.2865C16.2295 21.1675 16.4823 21.3132 16.4823 21.5238C16.4824 21.68 16.355 21.8072 16.198 21.8072Z"
    />
    <path
      d="M12.9462 7.95689L8.68064 5.40584C8.5905 5.3517 8.4779 5.3517 8.38775 5.40584L4.12224 7.95689C3.98775 8.03737 3.94396 8.21143 4.025 8.34579C4.10575 8.47985 4.28007 8.5235 4.41516 8.44272L8.53422 5.97926L12.6533 8.44272C12.6991 8.47022 12.7497 8.48324 12.7994 8.48324C12.8961 8.48324 12.9902 8.43421 13.0434 8.34576C13.1244 8.21143 13.0806 8.0374 12.9462 7.95689Z"
    />
    <path
      d="M13.651 19.2554C13.651 19.4119 13.7781 19.5388 13.9354 19.5388H15.0728C15.2301 19.5388 15.3572 19.4119 15.3572 19.2554V17.5547C15.3572 17.3983 15.2301 17.2713 15.0728 17.2713H13.9354C13.7781 17.2713 13.651 17.3983 13.651 17.5547V19.2554ZM14.2198 17.8382H14.7885V18.972H14.2198V17.8382Z"
    />
    <path
      d="M13.9354 16.1379H15.0728C15.2301 16.1379 15.3572 16.0109 15.3572 15.8545V14.1538C15.3572 13.9973 15.2301 13.8703 15.0728 13.8703H13.9354C13.7781 13.8703 13.651 13.9973 13.651 14.1538V15.8545C13.651 16.0109 13.7781 16.1379 13.9354 16.1379ZM14.2198 14.4372H14.7885V15.571H14.2198V14.4372Z"
    />
    <path
      d="M8.31732 19.0477C8.26586 19.1015 8.23489 19.1724 8.23489 19.2489C8.23489 19.3226 8.26586 19.3963 8.31732 19.4502C8.37105 19.5012 8.44209 19.5324 8.51913 19.5324C8.59304 19.5324 8.66665 19.5012 8.72094 19.4502C8.7721 19.3964 8.80337 19.3226 8.80337 19.2489C8.80337 19.1724 8.7721 19.1016 8.72094 19.0477C8.61576 18.9428 8.41938 18.9428 8.31732 19.0477Z"
    />
    <path
      d="M6.25312 15.8545C6.25312 16.0109 6.38022 16.1379 6.53748 16.1379H7.67493C7.83219 16.1379 7.95929 16.0109 7.95929 15.8545V14.1538C7.95929 13.9973 7.83219 13.8703 7.67493 13.8703H6.53748C6.38022 13.8703 6.25312 13.9973 6.25312 14.1538V15.8545ZM6.82187 14.4372H7.39061V15.571H6.82187V14.4372Z"
    />
    <path
      d="M9.38389 13.8703C9.22663 13.8703 9.09953 13.9973 9.09953 14.1538V15.8545C9.09953 16.0109 9.22663 16.1379 9.38389 16.1379H10.5213C10.6786 16.1379 10.8057 16.0109 10.8057 15.8545V14.1538C10.8057 13.9973 10.6786 13.8703 10.5213 13.8703H9.38389ZM10.237 15.571H9.66825V14.4372H10.237V15.571Z"
    />
    <path
      d="M8.52807 7.63435C7.27371 7.63435 6.25312 8.65165 6.25312 9.90196C6.25312 11.1523 7.27371 12.1696 8.52807 12.1696C9.78243 12.1696 10.803 11.1523 10.803 9.90196C10.803 8.65165 9.78243 7.63435 8.52807 7.63435ZM8.52807 11.6026C7.58709 11.6026 6.82187 10.8399 6.82187 9.90192C6.82187 8.96398 7.58709 8.20123 8.52807 8.20123C9.46905 8.20123 10.2343 8.96398 10.2343 9.90192C10.2343 10.8399 9.46905 11.6026 8.52807 11.6026Z"
    />
    <path
      d="M5.68937 17.5547C5.68937 17.7112 5.81647 17.8381 5.97373 17.8381H6.82683V20.3892C6.82683 20.5456 6.95393 20.6726 7.11118 20.6726C7.26844 20.6726 7.39554 20.5456 7.39554 20.3892V17.8381H9.67049V20.3892C9.67049 20.5456 9.79759 20.6726 9.95485 20.6726C10.1121 20.6726 10.2392 20.5456 10.2392 20.3892V17.8381H11.0923C11.2496 17.8381 11.3767 17.7112 11.3767 17.5547C11.3767 17.3983 11.2496 17.2713 11.0923 17.2713H5.97369C5.81647 17.2713 5.68937 17.3983 5.68937 17.5547Z"
    />
    <path
      d="M35.76 19.0093H35.4758V18.159C35.4758 18.0025 35.3488 17.8756 35.1916 17.8756C35.0345 17.8756 34.9074 18.0025 34.9074 18.159V19.2927C34.9074 19.4492 35.0345 19.5762 35.1916 19.5762H35.7601C35.9173 19.5762 36.0443 19.4492 36.0443 19.2927C36.0443 19.1363 35.9172 19.0093 35.76 19.0093Z"
    />
    <path
      d="M30.0761 26.3806H28.9387C28.7814 26.3806 28.6543 26.5076 28.6543 26.6641V28.3648C28.6543 28.5212 28.7814 28.6482 28.9387 28.6482H30.0761C30.2334 28.6482 30.3605 28.5212 30.3605 28.3648V26.6641C30.3605 26.5076 30.2334 26.3806 30.0761 26.3806ZM29.7918 28.0813H29.223V26.9476H29.7918L29.7918 28.0813Z"
    />
    <path
      d="M30.3605 23.2631C30.3605 23.1067 30.2334 22.9797 30.0761 22.9797H28.9387C28.7814 22.9797 28.6543 23.1067 28.6543 23.2631V24.9638C28.6543 25.1203 28.7814 25.2473 28.9387 25.2473H30.0761C30.2334 25.2473 30.3605 25.1203 30.3605 24.9638L30.3605 23.2631ZM29.7918 24.6804H29.223V23.5466H29.7918L29.7918 24.6804Z"
    />
    <path
      d="M30.9303 21.5627C30.9303 21.4063 30.8032 21.2793 30.646 21.2793H27.5179V20.9958C27.5179 20.8394 27.3908 20.7124 27.2336 20.7124C27.0763 20.7124 26.9492 20.8394 26.9492 20.9958V30.6331C26.9492 31.1019 27.332 31.4835 27.8023 31.4835H30.0773C30.5476 31.4835 30.9304 31.1019 30.9304 30.6331C30.9304 30.1643 30.5476 29.7828 30.0773 29.7828C30.0281 29.7828 29.9792 29.787 29.9311 29.7955C29.7352 29.4432 29.3612 29.2159 28.9398 29.2159C28.5184 29.2159 28.1444 29.4432 27.9485 29.7955C27.9005 29.787 27.8515 29.7828 27.8024 29.7828C27.702 29.7828 27.6073 29.8032 27.518 29.8349V21.8462H30.6461C30.8032 21.8462 30.9303 21.7192 30.9303 21.5627ZM27.8023 30.3497C27.8566 30.3497 27.9086 30.3653 27.9564 30.3959C28.0341 30.446 28.1316 30.4551 28.2175 30.4203C28.3034 30.3857 28.3665 30.3114 28.3878 30.2216C28.4475 29.9633 28.6745 29.7828 28.9398 29.7828C29.2051 29.7828 29.4317 29.9633 29.4917 30.2216C29.5131 30.3114 29.5762 30.3857 29.6621 30.4203C29.748 30.4551 29.8452 30.4461 29.9231 30.3959C30.1086 30.2768 30.3616 30.4225 30.3616 30.6331C30.3616 30.7893 30.2342 30.9166 30.0773 30.9166H27.8023C27.6453 30.9166 27.5179 30.7893 27.5179 30.6331C27.5179 30.4769 27.6453 30.3497 27.8023 30.3497Z"
    />
    <path
      d="M39.1786 30.3475H31.7851C31.6278 30.3475 31.5007 30.4746 31.5007 30.6311V31.1983C31.5007 31.3548 31.6278 31.4818 31.7851 31.4818C31.9423 31.4818 32.0694 31.3548 32.0694 31.1983V30.9147H38.8942V31.1983C38.8942 31.3548 39.0213 31.4818 39.1786 31.4818C39.3359 31.4818 39.463 31.3548 39.463 31.1983V30.6311C39.463 30.4746 39.3359 30.3475 39.1786 30.3475Z"
    />
    <path
      d="M31.7851 29.779C31.9423 29.779 32.0694 29.652 32.0694 29.4956V18.441C32.0694 18.2846 31.9423 18.1576 31.7851 18.1576C31.6278 18.1576 31.5007 18.2846 31.5007 18.441V29.4956C31.5007 29.652 31.6278 29.779 31.7851 29.779Z"
    />
    <path
      d="M39.1795 17.8756C39.0223 17.8756 38.8952 18.0025 38.8952 18.159V29.497C38.8952 29.6534 39.0223 29.7804 39.1795 29.7804C39.3368 29.7804 39.4639 29.6534 39.4639 29.497V18.159C39.4639 18.0025 39.3368 17.8756 39.1795 17.8756Z"
    />
    <path
      d="M43.716 20.7124C43.5587 20.7124 43.4316 20.8394 43.4316 20.9958V21.2793H40.3036C40.1463 21.2793 40.0192 21.4063 40.0192 21.5627C40.0192 21.7192 40.1463 21.8462 40.3036 21.8462H43.4316V29.8349C43.342 29.8031 43.2473 29.7827 43.1473 29.7827C43.0981 29.7827 43.0492 29.787 43.0011 29.7955C42.8052 29.4432 42.4312 29.2158 42.0098 29.2158C41.5884 29.2158 41.2144 29.4432 41.0185 29.7955C40.9704 29.787 40.9215 29.7827 40.8723 29.7827C40.402 29.7827 40.0192 30.1643 40.0192 30.6331C40.0192 31.1019 40.402 31.4834 40.8723 31.4834H43.1473C43.6176 31.4834 44.0004 31.1019 44.0004 30.6331V20.9959C44.0003 20.8394 43.8732 20.7124 43.716 20.7124ZM43.1472 30.9166H40.8723C40.7153 30.9166 40.5879 30.7893 40.5879 30.6332C40.5879 30.477 40.7153 30.3497 40.8723 30.3497C40.9266 30.3497 40.9786 30.3653 41.0264 30.3959C41.1043 30.4461 41.2016 30.4551 41.2875 30.4203C41.3733 30.3857 41.4365 30.3114 41.4578 30.2216C41.5175 29.9634 41.7444 29.7828 42.0097 29.7828C42.2751 29.7828 42.5017 29.9634 42.5617 30.2216C42.583 30.3114 42.6461 30.3857 42.732 30.4203C42.8179 30.4552 42.9151 30.4461 42.9931 30.3959C43.1787 30.2769 43.4315 30.4226 43.4315 30.6332C43.4316 30.7893 43.3042 30.9166 43.1472 30.9166Z"
    />
    <path
      d="M39.8954 17.0663L35.6299 14.5152C35.5397 14.4611 35.4271 14.4611 35.337 14.5152L31.0715 17.0663C30.937 17.1467 30.8932 17.3208 30.9742 17.4552C31.055 17.5892 31.2293 17.6329 31.3644 17.5521L35.4834 15.0886L39.6025 17.5521C39.6483 17.5796 39.6989 17.5926 39.7486 17.5926C39.8453 17.5926 39.9395 17.5436 39.9926 17.4551C40.0737 17.3208 40.0299 17.1468 39.8954 17.0663Z"
    />
    <path
      d="M40.6003 28.3648C40.6003 28.5212 40.7273 28.6482 40.8846 28.6482H42.0221C42.1793 28.6482 42.3064 28.5212 42.3064 28.3648V26.6641C42.3064 26.5076 42.1793 26.3806 42.0221 26.3806H40.8846C40.7273 26.3806 40.6003 26.5076 40.6003 26.6641V28.3648ZM41.169 26.9476H41.7377V28.0813H41.169V26.9476Z"
    />
    <path
      d="M40.8846 25.2473H42.0221C42.1793 25.2473 42.3064 25.1203 42.3064 24.9638V23.2631C42.3064 23.1067 42.1793 22.9797 42.0221 22.9797H40.8846C40.7273 22.9797 40.6003 23.1067 40.6003 23.2631V24.9638C40.6003 25.1203 40.7273 25.2473 40.8846 25.2473ZM41.169 23.5466H41.7377V24.6804H41.169V23.5466Z"
    />
    <path
      d="M35.2665 28.1571C35.2151 28.2109 35.1841 28.2818 35.1841 28.3583C35.1841 28.432 35.2151 28.5057 35.2665 28.5596C35.3203 28.6106 35.3913 28.6418 35.4684 28.6418C35.5423 28.6418 35.6159 28.6106 35.6702 28.5596C35.7213 28.5057 35.7526 28.432 35.7526 28.3583C35.7526 28.2818 35.7213 28.2109 35.6702 28.1571C35.565 28.0522 35.3686 28.0522 35.2665 28.1571Z"
    />
    <path
      d="M33.2023 24.9638C33.2023 25.1203 33.3294 25.2473 33.4867 25.2473H34.6242C34.7814 25.2473 34.9085 25.1203 34.9085 24.9638V23.2631C34.9085 23.1067 34.7814 22.9797 34.6242 22.9797H33.4867C33.3294 22.9797 33.2023 23.1067 33.2023 23.2631V24.9638ZM33.7711 23.5466H34.3398V24.6804H33.7711V23.5466Z"
    />
    <path
      d="M36.3331 22.9797C36.1759 22.9797 36.0488 23.1067 36.0488 23.2631V24.9638C36.0488 25.1203 36.1759 25.2473 36.3331 25.2473H37.4706C37.6278 25.2473 37.7549 25.1203 37.7549 24.9638V23.2631C37.7549 23.1067 37.6278 22.9797 37.4706 22.9797H36.3331ZM37.1862 24.6804H36.6175V23.5466H37.1862V24.6804Z"
    />
    <path
      d="M35.4773 16.7437C34.2229 16.7437 33.2023 17.761 33.2023 19.0113C33.2023 20.2616 34.2229 21.2789 35.4773 21.2789C36.7316 21.2789 37.7522 20.2616 37.7522 19.0113C37.7522 17.761 36.7316 16.7437 35.4773 16.7437ZM35.4773 20.712C34.5363 20.712 33.7711 19.9492 33.7711 19.0113C33.7711 18.0734 34.5363 17.3106 35.4773 17.3106C36.4183 17.3106 37.1835 18.0734 37.1835 19.0113C37.1835 19.9492 36.4183 20.712 35.4773 20.712Z"
    />
    <path
      d="M32.6386 26.6641C32.6386 26.8205 32.7657 26.9475 32.9229 26.9475H33.776V29.4986C33.776 29.655 33.9031 29.782 34.0604 29.782C34.2177 29.782 34.3448 29.655 34.3448 29.4986V26.9475H36.6197V29.4986C36.6197 29.655 36.7468 29.782 36.9041 29.782C37.0613 29.782 37.1884 29.655 37.1884 29.4986V26.9475H38.0415C38.1988 26.9475 38.3259 26.8205 38.3259 26.6641C38.3259 26.5076 38.1988 26.3806 38.0415 26.3806H32.9229C32.7657 26.3806 32.6386 26.5076 32.6386 26.6641Z"
    />
  </g>
  <g [ngClass]="[twMerge('stroke-primary', class)]">
    <path
      d="M7.41198 24.2393C7.42035 27.0539 8.37002 29.7843 10.0992 31.9656C11.8283 34.1468 14.23 35.6438 16.895 36.2014C19.5599 36.7591 22.3233 36.343 24.7142 35.024C25.8791 34.3813 26.9205 33.5436 27.8015 32.5532M4.12891 29.2798L7.04768 24.0215L12.1392 27.0791"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M33.745 13.2033C33.7367 10.3888 32.787 7.65832 31.0578 5.4771C29.3287 3.29588 26.927 1.7989 24.262 1.24122C21.5971 0.683541 18.8337 1.09967 16.4428 2.4187C15.2779 3.06137 14.2365 3.89902 13.3555 4.88946M37.0281 8.16288L34.1093 13.4212L29.0178 10.3635"
      stroke-width="2"
      stroke-linejoin="round"
    />
  </g>
</svg>
