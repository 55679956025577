/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { StateResource } from '@alfa-client/tech-shared';
import { Observable, of, switchMap, tap } from 'rxjs';
import { CommandResource } from './command.model';
import { isSuccessfulDone } from './command.util';

export function tapOnCommandSuccessfullyDone(
  runnable: (commandStateResource: StateResource<CommandResource>) => void,
): (
  source: Observable<StateResource<CommandResource>>,
) => Observable<StateResource<CommandResource>> {
  return (
    source: Observable<StateResource<CommandResource>>,
  ): Observable<StateResource<CommandResource>> => {
    return source.pipe(
      tap((commandStateResource: StateResource<CommandResource>) => {
        if (isSuccessfulDone(commandStateResource.resource)) runnable(commandStateResource);
      }),
    );
  };
}

export function switchMapCommandSuccessfullyDone(
  runnable: (command: StateResource<CommandResource>) => Observable<StateResource<CommandResource>>,
): (
  source: Observable<StateResource<CommandResource>>,
) => Observable<StateResource<CommandResource>> {
  return (
    source: Observable<StateResource<CommandResource>>,
  ): Observable<StateResource<CommandResource>> => {
    return source.pipe(
      switchMap((commandStateResource: StateResource<CommandResource>) => {
        if (isSuccessfulDone(commandStateResource.resource)) {
          return runnable(commandStateResource);
        } else {
          return of(commandStateResource);
        }
      }),
    );
  };
}
