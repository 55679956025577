/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { VorgangHeaderLinkRel, VorgangResource } from '@alfa-client/vorgang-shared';
import { ResourceFactory } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { PostfachMailListLinkRel } from './postfach.linkrel';
import { PostfachMailListResource } from './postfach.model';

@Injectable({ providedIn: 'root' })
export class PostfachRepository {
  constructor(private resourceFactory: ResourceFactory) {}

  public loadPostfachMailList(vorgang: VorgangResource): Observable<PostfachMailListResource> {
    return this.resourceFactory.from(vorgang).get(VorgangHeaderLinkRel.POSTFACH_MAILS);
  }

  public resetHasNewPostfachNachrichten(
    postfachNachrichtenList: PostfachMailListResource,
  ): Observable<unknown> {
    return this.resourceFactory
      .from(postfachNachrichtenList)
      .put(PostfachMailListLinkRel.RESET_HAS_NEW_POSTFACH_NACHRICHT, {
        hasNewPostfachNachricht: false,
      });
  }
}
