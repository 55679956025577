/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { StateResource, getEmbeddedResources } from '@alfa-client/tech-shared';
import { BinaryFileListLinkRel } from './binary-file.linkrel';
import { BinaryFileListResource, BinaryFileResource } from './binary-file.model';

export function getBinaryFiles(
  binaryFileListResource: StateResource<BinaryFileListResource>,
): BinaryFileResource[] {
  return getEmbeddedResources(binaryFileListResource, BinaryFileListLinkRel.FILE_LIST);
}

export enum BinaryFileIcon {
  'application/pdf' = 'pdf',
  'application/json' = 'json',
  'application/msword' = 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'doc',
  'application/xml' = 'xml',
  'text/xml' = 'xml',
  'image/apng' = 'image',
  'image/gif' = 'image',
  'image/jpeg' = 'image',
  'image/png' = 'image',
  'image/svg+xml' = 'image',
}
