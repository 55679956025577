/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { toResource } from 'libs/tech-shared/test/resource';
import {
  NotificationSendForTypes,
  UserNotificationsTypes,
  UserSettings,
  UserSettingsResource,
} from '../src/lib/user-settings.model';

export function createUserSettingsResource(linkRelations: string[] = []): UserSettingsResource {
  return toResource(createUserSettings(), linkRelations);
}

export function createUserSettings(value: boolean = true): UserSettings {
  return {
    [UserNotificationsTypes.VORGANGE_CREATED]: value,
    [UserNotificationsTypes.VORGANG_ASSIGNED_TO_USER]: value,
    [UserNotificationsTypes.POSTFACH_NACHRICHT_FROM_ANTRAGSTELLER]: value,
    [UserNotificationsTypes.WIEDERVORLAGE_DUE_TODAY]: value,
    [UserNotificationsTypes.NOTIFICATIONS_SEND_FOR]: mapBooleanToNotificationSendFor(value),
  };
}

export function mapBooleanToNotificationSendFor(value: boolean): NotificationSendForTypes {
  return value ? NotificationSendForTypes.ALL : NotificationSendForTypes.NONE;
}

export function mapNotificationSendForToBoolean(value: NotificationSendForTypes): boolean {
  return value === NotificationSendForTypes.ALL;
}
