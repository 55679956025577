/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { InvalidParam } from '@alfa-client/tech-shared';
import { Component, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  template: 'NO UI',
})
export abstract class FormControlEditorAbstractComponent implements ControlValueAccessor, OnInit, OnDestroy {
  readonly fieldControl: UntypedFormControl = new UntypedFormControl();
  public onChange = (text: string | Date) => undefined;
  public onTouched = () => undefined;

  private changesSubscr: Subscription;
  private statusSubscr: Subscription;

  disabled: boolean = false;

  constructor(@Self() @Optional() public control: NgControl | null) {
    if (this.control) this.control.valueAccessor = this;

    this.changesSubscr = this.fieldControl.valueChanges.subscribe((val) => {
      this.onChange(val);
      this.setErrors();
    });
  }

  ngOnInit(): void {
    if (!this.statusSubscr && this.control)
      this.statusSubscr = this.control.statusChanges.subscribe(() => {
        this.setErrors();
      });
  }

  touch(): void {
    this.onTouched();
  }

  writeValue(text: string): void {
    this.fieldControl.setValue(text);
    this.setErrors();
  }
  registerOnChange(fn: (text: string | Date) => {}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    if (this.changesSubscr) this.changesSubscr.unsubscribe();
    if (this.statusSubscr) this.statusSubscr.unsubscribe();
  }

  setErrors(): void {
    if (this.control) {
      this.fieldControl.setErrors(this.control.errors);
      if (this.control.invalid) this.fieldControl.markAsTouched();
    }
  }

  get invalidParams(): InvalidParam[] {
    return this.fieldControl.errors ?
        Object.keys(this.fieldControl.errors).map((key) => <InvalidParam>this.fieldControl.errors[key])
      : [];
  }
}
