<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>

  <input
    #autocompleteInput
    [attr.data-test-id]="(label | convertForDataTest) + '-autocomplete-input'"
    [required]="required"
    type="text"
    matInput
    [matAutocomplete]="auto"
    [formControl]="fieldControl"
    [name]="label"
    (keyup)="keyUp($event)"
    (keyup.enter)="onEnter()"
  />

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option
      *ngFor="let value of values"
      [attr.data-test-id]="(value.label | convertForDataTest) + '-autocomplete-option'"
      [value]="value.value"
      (keyup.enter)="onSelection(value)"
      (onSelectionChange)="onSelection(value)"
    >
      {{ value.label }}
    </mat-option>
  </mat-autocomplete>

  <mat-error>
    <ozgcloud-validation-error
      [attr.data-test-id]="(label | convertForDataTest) + '-autocomplete-error'"
      [invalidParams]="invalidParams"
      [label]="label"
    >
    </ozgcloud-validation-error>
  </mat-error>
</mat-form-field>
