<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<mat-accordion displayMode="flat" hideToggle>
  <mat-expansion-panel [expanded]="expanded" data-test-class="expansion-panel" #expansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title data-test-class="panel-title">
        <button
          mat-icon-button
          tabindex="-1"
          [attr.aria-label]="'Bereich ' + (expansionPanel.expanded ? 'reduzieren' : 'erweitern')"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
        <h3>{{ headline }}</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- expandable start -->
    <div
      [ngStyle]="{
        visibility: expansionPanel.expanded ? 'visible' : 'hidden',
        display: expansionPanel.expanded ? 'block' : 'none'
      }"
    >
      <ng-content></ng-content>
    </div>
    <!-- expandable end -->
  </mat-expansion-panel>
</mat-accordion>
