/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import {
  decodeUrlFromEmbedding,
  isEmptyObject,
  isNotNil,
  isNotNull,
  isNotUndefined,
} from '@alfa-client/tech-shared';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  PRIMARY_OUTLET,
  Params,
  Router,
  UrlSegment,
} from '@angular/router';
import { ResourceUri } from '@ngxp/rest';
import { OAuthService } from 'angular-oauth2-oidc';
import { has, isNil, isUndefined } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';
import { NavigationFacade } from './+state/navigation.facade';
import { RouteData } from './+state/navigation.models';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  //@deprecated use NavigationFacade#getCurrentRouteData;
  readonly routeUrlSegment$: BehaviorSubject<UrlSegment[]> = new BehaviorSubject<UrlSegment[]>([]);
  private readonly routeParameter$: BehaviorSubject<Params> = new BehaviorSubject<Params>({});
  //

  public static readonly URL_PARAM_MY_VORGAENGE: string = 'meine';
  public static readonly URL_PARAM_MY_VORGANG: string = 'vorgang';
  public static readonly URL_PARAM_ALLE: string = 'alle';
  public static readonly URL_PARAM_SEARCH: string = 'search';
  public static readonly URL_PARAM_UNASSIGNED: string = 'unassigned';

  public readonly VORGANG_WITH_EINGANG_URL: string = 'vorgangWithEingangUrl';

  static readonly ROOT_PATH: string = '/';
  static readonly VORGANG_BASE_PATH: string =
    NavigationService.ROOT_PATH + NavigationService.URL_PARAM_MY_VORGANG + '/';
  public static readonly MEINE_VORGAENGE_PATH: string =
    NavigationService.ROOT_PATH + NavigationService.URL_PARAM_MY_VORGAENGE + '/';
  public static readonly ALLE_VORGAENGE_PATH: string =
    NavigationService.ROOT_PATH + NavigationService.URL_PARAM_ALLE + '/';
  public static readonly UNASSIGNED_VORGAENGE_PATH: string =
    NavigationService.ROOT_PATH + NavigationService.URL_PARAM_UNASSIGNED + '/';

  private routeSubscription: Subscription;
  private urlSubscription: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private facade: NavigationFacade,
    private authService: OAuthService,
  ) {
    this.init();
  }

  private init(): void {
    this.prefillRouterData();

    this.subscribeToRouterEvents();
  }

  private prefillRouterData(): void {
    this.getLastFirstChild(this.activatedRoute.root).params.subscribe((params: Params) =>
      this.routeParameter$.next(params),
    );
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        filter(() => isNotNull(this.authService.getAccessToken())),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getLastFirstChild(this.activatedRoute)),
        filter((route) => route.outlet === PRIMARY_OUTLET),
      )
      .subscribe((route) => this.updateRouteData(route));
  }

  private getLastFirstChild(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) route = route.firstChild;
    return route;
  }

  updateRouteData(route: ActivatedRoute): void {
    if (isNotUndefined(this.urlSubscription)) this.urlSubscription.unsubscribe();
    this.updateRouteUrlSegment(route);
    if (isNotUndefined(this.routeSubscription)) this.routeSubscription.unsubscribe();
    this.updateRouteParameter(route);
  }

  updateRouteUrlSegment(route: ActivatedRoute): void {
    this.urlSubscription = route.url.subscribe((currentUrlSegment: UrlSegment[]) =>
      this.routeUrlSegment$.next(currentUrlSegment),
    );
  }

  updateRouteParameter(route: ActivatedRoute): void {
    this.routeSubscription = route.params.subscribe((params: Params) => {
      if (this.isNewRoute(params)) {
        this.setRouteParameter(params);
      }
    });
  }

  isNewRoute(newParams: Params): boolean {
    return newParams != this.routeParameter$.value;
  }

  setRouteParameter(params: Params): void {
    this.routeParameter$.next(params);
  }

  public urlChanged(): Observable<Params> {
    return this.routeParameter$.asObservable();
  }

  public getParam(paramName: string): string {
    return this.routeParameter$.value[paramName];
  }

  public getDecodedParam(paramName: string): string {
    if (this.isParamPresent(paramName)) return undefined;
    return NavigationService.decodeParamUrl(this.routeParameter$.value[paramName]);
  }

  static decodeParamUrl(encodedUrl: string): string {
    let decodeUrl: string;

    try {
      decodeUrl = decodeUrlFromEmbedding(encodedUrl);
    } catch (e) {
      console.warn('cannot decode url param', e);
    }
    return decodeUrl;
  }

  public getDecodedUriParam(paramName: string): string {
    if (this.isParamPresent(paramName)) return undefined;
    return decodeURI(this.routeParameter$.value[paramName]);
  }

  private isParamPresent(paramName: string): boolean {
    return (
      isEmptyObject(this.routeParameter$.value) ||
      isUndefined(this.routeParameter$.value[paramName])
    );
  }

  static isVorgangListPage(params: Params): boolean {
    return isEmptyObject(params) || has(params, 'status') || has(params, this.URL_PARAM_SEARCH);
  }

  static isVorgangDetailPage(params: Params, paramName: string): boolean {
    return Object.keys(params).length === 1 && isNotUndefined(params[paramName]);
  }

  static isSearch(params: Params): boolean {
    return Object.keys(params).length === 1 && !isNil(params[NavigationService.URL_PARAM_SEARCH]);
  }

  public navigateRelativeTo(relativePath: string, relativeTo: ActivatedRoute): void {
    this.router.navigate([relativePath], { relativeTo });
  }

  public search(searchString: string): void {
    this.router.navigate([
      NavigationService.ROOT_PATH,
      this.getFilterPathSegment(),
      NavigationService.URL_PARAM_SEARCH,
      encodeURIComponent(searchString),
    ]);
  }

  public navigateToVorgangList(): void {
    this.router.navigate([NavigationService.ROOT_PATH, this.getFilterPathSegment()]);
  }

  getFilterPathSegment(): string {
    const firstPathSegment: string = this.router.url.split('/')[1];
    return this.isFilterPathSegment(firstPathSegment) ? firstPathSegment : (
        NavigationService.URL_PARAM_ALLE
      );
  }

  isFilterPathSegment(pathSegment: string): boolean {
    return (
      pathSegment === NavigationService.URL_PARAM_ALLE ||
      pathSegment === NavigationService.URL_PARAM_MY_VORGAENGE ||
      pathSegment === NavigationService.URL_PARAM_UNASSIGNED
    );
  }

  navigate(navigationRoute: string, queryParams?: Params): void {
    this.router.navigate([navigationRoute], { queryParams });
  }

  public navigateToVorgang(linkUri: ResourceUri): void {
    this.router.navigate([
      NavigationService.ROOT_PATH,
      NavigationService.URL_PARAM_MY_VORGANG,
      linkUri,
    ]);
  }

  public isPostfachPage(): boolean {
    const postfachUrlSegement: UrlSegment = this.routeUrlSegment$.value.find((urlSegment) =>
      urlSegment.path.includes('postfach'),
    );
    return isNotNil(postfachUrlSegement);
  }

  public getCurrentRouteData(): Observable<RouteData> {
    return this.facade.getCurrentRouteData();
  }

  public navigateByPathSegments(pathSegments: string[]): void {
    this.router.navigate(pathSegments);
  }

  public navigateByFilter(filter: string): void {
    let urlSegments: string[] = this.getUrlSegments();
    urlSegments[1] = filter;
    this.router.navigate(urlSegments);
  }

  private getUrlSegments(): string[] {
    return this.router.url.split('/');
  }

  isNotRootPath(): boolean {
    return this.router.url !== '/';
  }
}
