/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { twMerge } from 'tailwind-merge';

import { IconVariants, iconVariants } from '../iconVariants';

@Component({
  selector: 'ods-exclamation-icon',
  standalone: true,
  imports: [NgClass],
  template: `<svg
    xmlns="http://www.w3.org/2000/svg"
    [ngClass]="[twMerge(iconVariants({ size }), 'fill-error', class)]"
    aria-hidden="true"
    viewBox="0 0 20 20"
    fill="inherit"
  >
    <path
      d="M10.3069 14.7308C10.5416 14.7308 10.7383 14.6533 10.8971 14.4985C11.0559 14.3437 11.1353 14.1518 11.1353 13.923C11.1353 13.6942 11.0559 13.5023 10.8971 13.3475C10.7383 13.1928 10.5416 13.1155 10.3069 13.1155C10.0722 13.1155 9.87548 13.1928 9.71668 13.3475C9.55789 13.5023 9.47849 13.6942 9.47849 13.923C9.47849 14.1518 9.55789 14.3437 9.71668 14.4985C9.87548 14.6533 10.0722 14.7308 10.3069 14.7308ZM10.3072 11.077C10.5253 11.077 10.7079 11.0051 10.8551 10.8613C11.0024 10.7176 11.0761 10.5395 11.0761 10.327V5.827C11.0761 5.6145 11.0023 5.43633 10.8548 5.2925C10.7073 5.14883 10.5246 5.077 10.3066 5.077C10.0885 5.077 9.9059 5.14883 9.75873 5.2925C9.61139 5.43633 9.53772 5.6145 9.53772 5.827V10.327C9.53772 10.5395 9.61148 10.7176 9.75899 10.8613C9.9065 11.0051 10.0892 11.077 10.3072 11.077ZM10.3087 19.5C8.96109 19.5 7.69442 19.2507 6.50868 18.752C5.32295 18.2533 4.29156 17.5766 3.41452 16.7218C2.53748 15.8669 1.84308 14.8617 1.33132 13.706C0.81973 12.5503 0.563934 11.3156 0.563934 10.0017C0.563934 8.68775 0.819644 7.45267 1.33106 6.2965C1.84248 5.14033 2.53654 4.13467 3.41324 3.2795C4.28994 2.42433 5.3209 1.74725 6.50612 1.24825C7.69134 0.749417 8.95767 0.5 10.3051 0.5C11.6527 0.5 12.9194 0.749333 14.1051 1.248C15.2909 1.74667 16.3222 2.42342 17.1993 3.27825C18.0763 4.13308 18.7707 5.13833 19.2825 6.294C19.7941 7.44967 20.0499 8.68442 20.0499 9.99825C20.0499 11.3123 19.7942 12.5473 19.2827 13.7035C18.7713 14.8597 18.0773 15.8653 17.2006 16.7205C16.3239 17.5757 15.2929 18.2528 14.1077 18.7518C12.9225 19.2506 11.6561 19.5 10.3087 19.5Z"
    />
  </svg>`,
})
export class ExclamationIconComponent {
  @Input() size: IconVariants['size'] = 'medium';
  @Input() class: string = undefined;

  iconVariants = iconVariants;
  twMerge = twMerge;
}
