/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  addRequestHeader,
  HttpErrorHandler,
  isConnectionTimeout,
  sleep,
} from '@alfa-client/tech-shared';
import { HttpXsrfInterceptor } from 'libs/tech-shared/src/lib/interceptor/http-xsrf.interceptor';
import { isNull } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { DialogService } from '../ui/dialog/dialog.service';
import { RetryInTimeDialog } from '../ui/dialog/retry-in-time.dialog';

@Injectable()
export class HttpConnectionTimeoutInterceptor implements HttpInterceptor {
  readonly RETRY_IN_MS = 1000;
  retryDialog: RetryInTimeDialog = null;

  constructor(
    private dialogService: DialogService,
    private errorHandler: HttpErrorHandler,
    private tokenExtractor: HttpXsrfTokenExtractor,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const defaultHandling: boolean = this.errorHandler.shouldDoRetry();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) =>
        this.handleError(request, next, error, defaultHandling),
      ),
      finalize(() => this.errorHandler.enableRetry()),
    );
  }

  handleError(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    response: HttpErrorResponse,
    defaultHandling: boolean,
  ): Observable<any> {
    if (defaultHandling) {
      if (isConnectionTimeout(response.status)) {
        this.initRetry();

        if (this.retryDialog.shouldRetry()) {
          return this.doRetry(request, next);
        }
      }
    }
    return throwError({ response });
  }

  initRetry(): void {
    if (isNull(this.retryDialog)) {
      this.retryDialog = this.dialogService.getRetryDialog();
      this.retryDialog.show();
    }
  }

  doRetry(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    this.sleepUntilRetry();
    return next
      .handle(addRequestHeader(request, HttpXsrfInterceptor.X_XSRF_TOKEN_HEADER, this.getToken()))
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleErrorRetry(request, next, error)),
        tap((response) => this.handleResponse(response)),
        finalize(() => this.errorHandler.enableRetry()),
      );
  }

  sleepUntilRetry(): void {
    sleep(this.RETRY_IN_MS);
  }

  handleErrorRetry(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    response: HttpErrorResponse,
  ): Observable<any> {
    if (isConnectionTimeout(response.status)) {
      if (this.retryDialog.shouldRetry()) {
        return this.doRetry(request, next);
      } else {
        this.stopRetry();
      }
    }
    return throwError({ response });
  }

  stopRetry(): void {
    this.retryDialog.finish();
    this.retryDialog = null;
  }

  private getToken(): string {
    return this.tokenExtractor.getToken();
  }

  handleResponse(response: any): void {
    if (this.isValidResponse(response)) {
      this.dialogService.closeById(RetryInTimeDialog.ID);
      this.retryDialog = null;
    }
  }

  isValidResponse(response: any): boolean {
    return response.type !== 0 && response.ok;
  }
}
