<!--

    Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
    Ministerpräsidenten des Landes Schleswig-Holstein
    Staatskanzlei
    Abteilung Digitalisierung und zentrales IT-Management der Landesregierung

    Lizenziert unter der EUPL, Version 1.2 oder - sobald
    diese von der Europäischen Kommission genehmigt wurden -
    Folgeversionen der EUPL ("Lizenz");
    Sie dürfen dieses Werk ausschließlich gemäß
    dieser Lizenz nutzen.
    Eine Kopie der Lizenz finden Sie hier:

    https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12

    Sofern nicht durch anwendbare Rechtsvorschriften
    gefordert oder in schriftlicher Form vereinbart, wird
    die unter der Lizenz verbreitete Software "so wie sie
    ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
    ausdrücklich oder stillschweigend - verbreitet.
    Die sprachspezifischen Genehmigungen und Beschränkungen
    unter der Lizenz sind dem Lizenztext zu entnehmen.

-->
<ng-container [formArrayName]="parentFormArrayName">
  <ng-container *ngFor="let hiddenInput of fileLinkControls.controls; let i = index">
    <input id="file-link-{{ i }}" type="hidden" [formControlName]="i" />
  </ng-container>
</ng-container>

<input
  #inputElement
  type="file"
  [id]="uploadFileId"
  [accept]="accept"
  [attr.data-test-id]="(label | convertForDataTest) + '-file-upload-input'"
  (click)="resetInput()"
/>

<label [attr.for]="uploadFileId" matRipple [attr.aria-label]="label">
  <mat-icon *ngIf="!uploadInProgress.loading">attach_file</mat-icon>

  <ozgcloud-spinner [stateResource]="uploadInProgress" [diameter]="22" padding="0">
  </ozgcloud-spinner>
</label>

<mat-error>
  <ozgcloud-validation-error
    [attr.data-test-id]="(label | convertForDataTest) + '-file-upload-error'"
    [invalidParams]="invalidParams"
    [label]="label"
  >
  </ozgcloud-validation-error>
</mat-error>
