/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export const ESCAPE_KEY = 'Escape';
export const ENTER_KEY = 'Enter';
export const ARROW_DOWN_KEY = 'ArrowDown';
export const ARROW_UP_KEY = 'ArrowUp';

export function isEscapeKey(keyboardEvent: KeyboardEvent) {
  return keyboardEvent.key === ESCAPE_KEY;
}

export function isEnterKey(keyboardEvent: KeyboardEvent) {
  return keyboardEvent.key === ENTER_KEY;
}

export function isArrowDownKey(keyboardEvent: KeyboardEvent) {
  return keyboardEvent.key === ARROW_DOWN_KEY;
}

export function isArrowUpKey(keyboardEvent: KeyboardEvent) {
  return keyboardEvent.key === ARROW_UP_KEY;
}
