/*
 * Copyright (C) 2024 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createAriaLabelForIconButton, StateResource } from '@alfa-client/tech-shared';
import { Resource } from '@ngxp/rest';

@Component({
  selector: 'ozgcloud-icon-button-primary',
  templateUrl: './ozgcloud-icon-button-primary.component.html',
  styleUrls: ['./ozgcloud-icon-button-primary.component.scss'],
})
export class OzgcloudIconButtonPrimaryComponent implements OnInit {
  @Input() svgIcon: string;
  @Input() stateResource: StateResource<Resource>;
  @Input() tooltip = '';
  @Output() public clickEmitter: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  ariaLabel = '';

  ngOnInit(): void {
    this.ariaLabel = createAriaLabelForIconButton(this.tooltip, this.svgIcon);
  }
}
