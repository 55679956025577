/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { CommandResource, CommandStatus } from '@alfa-client/command-shared';
import { isNotUndefined } from '@alfa-client/tech-shared';
import { isUndefined } from 'lodash-es';
import { Subscription } from 'rxjs';
import { SnackbarErrorComponent } from './snackbar-error/snackbar-error.component';
import { SnackbarInfoComponent } from './snackbar-info/snackbar-info.component';

@Injectable({ providedIn: 'root' })
export class SnackBarService {
  private subscription: Subscription;

  snackBarRef: MatSnackBarRef<SnackbarInfoComponent | SnackbarErrorComponent>;

  private durationTime: number = 10000;

  constructor(private snackBar: MatSnackBar) {}

  public show(commandResource: CommandResource, message: string, revokeAction?: () => void): void {
    if (commandResource.status === CommandStatus.ERROR) {
      this.showError(message);
    } else {
      this.openFromComponent(SnackbarInfoComponent, { data: { message, commandResource } });
    }
    this.handleRevokeAction(revokeAction);
  }

  private handleRevokeAction(revokeAction?: () => void): void {
    if (isNotUndefined(revokeAction)) {
      this.listenOnRevokeAction(revokeAction);
    }
  }

  public showError(message: string): void {
    this.openFromComponent(SnackbarErrorComponent, { data: { message }, panelClass: 'error' });
  }

  public showInfo(message: string): void {
    this.openFromComponent(SnackbarInfoComponent, { data: { message } });
  }

  private openFromComponent(
    component: ComponentType<SnackbarInfoComponent | SnackbarErrorComponent>,
    config: MatSnackBarConfig,
  ): void {
    this.closeSnackBarIfVisible();
    this.snackBarRef = this.snackBar.openFromComponent(component, {
      ...config,
      duration: this.durationTime,
    });
    this.listenOnAfterDismissed();
  }

  public isVisible(): boolean {
    return isNotUndefined(this.snackBarRef);
  }

  public isNotVisible(): boolean {
    return isUndefined(this.snackBarRef);
  }

  closeSnackBarIfVisible(): void {
    if (isNotUndefined(this.snackBarRef)) this.closeSnackBar();
  }

  listenOnRevokeAction(revokeAction: () => void): void {
    this.subscription.add(
      this.snackBarRef.onAction().subscribe(() => {
        revokeAction();
        this.closeSnackBar();
      }),
    );
  }

  listenOnAfterDismissed(): void {
    this.subscription = this.snackBarRef.afterDismissed().subscribe(() => this.closeSnackBar());
  }

  closeSnackBar(): void {
    this.snackBarRef.dismiss();
    this.unsubscribe();
  }

  private unsubscribe(): void {
    if (isNotUndefined(this.subscription)) this.subscription.unsubscribe();
  }
}
