/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable, isDevMode } from '@angular/core';
import {
  createEmptyStateResource,
  createStateResource,
  doIfLoadingRequired,
  isNotNull,
  StateResource,
} from '@alfa-client/tech-shared';
import { getUrl, Resource } from '@ngxp/rest';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, startWith, switchMap, tap } from 'rxjs/operators';
import { ApiRootFacade } from './+state/api-root.facade';
import { ApiDownloadToken, ApiRootResource } from './api-root.model';
import { ApiRootRepository } from './api-root.repository';

@Injectable()
export class ApiRootService {
  readonly apiRootStateResource: BehaviorSubject<StateResource<ApiRootResource>> =
    new BehaviorSubject<StateResource<ApiRootResource>>(createEmptyStateResource());

  constructor(
    private repository: ApiRootRepository,
    private apiRootFacade: ApiRootFacade,
  ) {}

  public getApiRoot(): Observable<StateResource<ApiRootResource>> {
    return this.apiRootStateResource.asObservable().pipe(
      tap((apiRootStateResource: StateResource<ApiRootResource>) =>
        this.handleApiRootChanges(apiRootStateResource),
      ),
      filter((apiRootStateResource: StateResource<ApiRootResource>) =>
        this.shouldEmit(apiRootStateResource),
      ),
      startWith(createEmptyStateResource<ApiRootResource>(true)),
    );
  }

  handleApiRootChanges(apiRootStateResource: StateResource<ApiRootResource>): void {
    doIfLoadingRequired(apiRootStateResource, () => this.loadApiRoot());
  }

  loadApiRoot(): void {
    this.apiRootStateResource.next({ ...this.apiRootStateResource.value, loading: true });
    this.doLoadApiRoot();
  }

  doLoadApiRoot(): void {
    this.repository
      .loadApiRoot()
      .pipe(first())
      .subscribe((apiRoot: ApiRootResource) =>
        this.apiRootStateResource.next(createStateResource(apiRoot)),
      );
  }

  shouldEmit(apiRootStateResource: StateResource<ApiRootResource>): boolean {
    return (
      (apiRootStateResource.loaded || apiRootStateResource.loading) && !apiRootStateResource.reload
    );
  }

  //TOOD: In der Facade zur Verfuegung stellen, aufrufende Stelle anpassen und den service lib protected machen
  public getDownloadToken(resource: Resource): Observable<ApiDownloadToken> {
    return this.apiRootFacade.getApiRoot().pipe(
      filter((apiRoot) => isNotNull(apiRoot.resource)),
      switchMap((apiRoot) => this.repository.downloadToken(apiRoot.resource, getUrl(resource))),
    );
  }
}
