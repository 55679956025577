/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
export const localStorageDark: string = 'dark';
export const localStorageFilter: string = 'filter';
export const localStorageView: string = 'view';

export function setDarkModeIntoStorage(value: string): void {
  localStorage.setItem(localStorageDark, value);
}

export function getDarkModeFromLocalStorage(): string {
  return localStorage.getItem(localStorageDark);
}

export function setFilterIntoStorage(value: string): void {
  localStorage.setItem(localStorageFilter, value);
}

export function getFilterFromLocalStorage(): string {
  return localStorage.getItem(localStorageFilter);
}

export function setViewIntoStorage(value: string): void {
  localStorage.setItem(localStorageView, value);
}

export function getViewFromLocalStorage(): string {
  return localStorage.getItem(localStorageView);
}

export function removeLocalStorageView(): void {
  localStorage.removeItem(localStorageView);
}

export function removeLocalStorageFilter(): void {
  localStorage.removeItem(localStorageFilter);
}

export function removeIdTokenFromSessionStorage(): void {
  sessionStorage.removeItem('id_token');
}
