/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { doIfLoadingRequired, StateResource } from '@alfa-client/tech-shared';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApiRootResource } from '../api-root.model';

import * as ApiRootActions from './api-root.actions';
import * as ApiRootSelectors from './api-root.selectors';

@Injectable()
export class ApiRootFacade {
  constructor(private readonly store: Store) {}

  getApiRoot(): Observable<StateResource<ApiRootResource>> {
    return this.store
      .select(ApiRootSelectors.apiRoot)
      .pipe(
        filter(
          (apiRoot) =>
            !doIfLoadingRequired(apiRoot, () => this.store.dispatch(ApiRootActions.loadApiRoot())),
        ),
      );
  }
}
