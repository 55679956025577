/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApiRootModule } from '@alfa-client/api-root-shared';
import { TechSharedModule } from '@alfa-client/tech-shared';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HintEffects } from './+state/hint.effects';
import { HintFacade } from './+state/hint.facade';
import { HINT_FEATURE_KEY, reducer } from './+state/hint.reducer';
import { HintRepository } from './+state/hint.repository';
import { HintService } from './hint.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(HINT_FEATURE_KEY, reducer),
    EffectsModule.forFeature([HintEffects]),
    TechSharedModule,
    ApiRootModule,
  ],
  providers: [HintService, HintFacade, HintRepository],
})
export class HintSharedModule {}
