/*
 * Copyright (C) 2023 Das Land Schleswig-Holstein vertreten durch den
 * Ministerpräsidenten des Landes Schleswig-Holstein
 * Staatskanzlei
 * Abteilung Digitalisierung und zentrales IT-Management der Landesregierung
 *
 * Lizenziert unter der EUPL, Version 1.2 oder - sobald
 * diese von der Europäischen Kommission genehmigt wurden -
 * Folgeversionen der EUPL ("Lizenz");
 * Sie dürfen dieses Werk ausschließlich gemäß
 * dieser Lizenz nutzen.
 * Eine Kopie der Lizenz finden Sie hier:
 *
 * https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12
 *
 * Sofern nicht durch anwendbare Rechtsvorschriften
 * gefordert oder in schriftlicher Form vereinbart, wird
 * die unter der Lizenz verbreitete Software "so wie sie
 * ist", OHNE JEGLICHE GEWÄHRLEISTUNG ODER BEDINGUNGEN -
 * ausdrücklich oder stillschweigend - verbreitet.
 * Die sprachspezifischen Genehmigungen und Beschränkungen
 * unter der Lizenz sind dem Lizenztext zu entnehmen.
 */
import { Injectable } from '@angular/core';
import { Resource, ResourceFactory } from '@ngxp/rest';
import { Observable } from 'rxjs';
import { CommandLinkRel } from './command.linkrel';
import {
  CommandListResource,
  CommandResource,
  CommandStatus,
  CreateCommand,
} from './command.model';

@Injectable({ providedIn: 'root' })
export class CommandRepository {
  constructor(private resourceFactory: ResourceFactory) {}

  public createCommand(
    resource: Resource,
    linkrel: string,
    command: CreateCommand,
  ): Observable<CommandResource> {
    return this.resourceFactory.from(resource).post(linkrel, command);
  }

  public getCommand(resource: CommandResource): Observable<CommandResource> {
    return this.resourceFactory.from(resource).get(CommandLinkRel.SELF);
  }

  public getPendingCommands(resource: Resource, linkRel: string): Observable<CommandListResource> {
    return this.resourceFactory.from(resource).get(linkRel);
  }

  public revokeCommand(resource: CommandResource): Observable<CommandResource> {
    return this.resourceFactory
      .from(resource)
      .patch(CommandLinkRel.REVOKE, { status: CommandStatus.REVOKED });
  }

  public getEffectedResource<T>(command: CommandResource): Observable<T> {
    return this.resourceFactory.from(command).get(CommandLinkRel.EFFECTED_RESOURCE);
  }
}
